
export default class TicketData {

    logoData                  =   '';
    titlesRow                 =   [];
    numbers                   =   [];
    columnsWidth              =   [];
    columnsAlign              =   [];
    totalAmount               =   [];
    numbersAmount             =   [];
    gameDate                  =   [];
    terminalData              =   [];
    qrValue                   =   '';
    printDate                 =   '';
    message                   =   '';
    logoWidth                 =   0;
    logoHeight                =   0;
    fontSize                  =   23;
    decreseFontNumbers        =   0;

    constructor() 
    {
        
    }
 

   
}