<template>
  <div class="control-panel-portrait-lt">
    <div class="control-row-notifacitons">
      <div class="notifications-alert" :class="getAlertTitleClass()">
        <v-icon v-if="alertType === 'info'" class="alert-icon-info"
          >mdi-exclamation-thick</v-icon
        >
        <v-icon v-if="alertType === 'error'" class="alert-icon-error"
          >mdi-alert</v-icon
        >
        <p class="alert-text">{{ alertText }}</p>
      </div>
    </div>
    <div class="control-row-buttons">
   <!--    <v-navigation-drawer
        v-model="drawer"
        right
        absolute
        temporary
        width="75%"
        color="#0077AD"
      >
        <div class="controls-drawer-container">
          <div class="first-row">
            <v-btn
              v-for="(day, index) of days"
              :key="index"
              :id="'btnRaffleOption-' + index"
              class="raffle-option-button"
              @click="onRaffleButtonClick(index)"
              elevation="2"
            >
              <span v-if="day.weekday != 'HOY'">{{
                day.weekday + " " + day.date
              }}</span>
              <span v-else>{{ day.weekday }}</span>
            </v-btn>
          </div>
          <div class="second-row">
            <v-btn
              v-for="(time, index) of times"
              :key="index"
              class="time-option-button"
              :id="'btnRaffleTimeOption-' + index"
              @click="onRaffleTimeButtonClick(index)"
              elevation="2"
              :disabled="getDisabledTimeStatus(time)"
            >
              {{ time }}
            </v-btn>
          </div>
        </div>
      </v-navigation-drawer> -->
     
        <div class="control-row-1">
           <v-btn
              id="btnFirstDay"
              @click="onFirstDayClick"
              class="display-button"
              elevation="2"
            >
              {{ lblFirstDay }}
            </v-btn>
            <v-btn
              id="btnSecondDay"
              @click="onSecondDayClick"
              class="display-button "
              elevation="2"
            >
              {{ lblSecondDay }}
            </v-btn>
            <v-btn
              id="btnOrdDes"
              @click="onRevengeClick"
              class="control-revenge-button"
              :class="[revengeActive ? 'control-revenge-active' : '']"
               :disabled="disabledControls"
             
              elevation="2"
            >
              {{ lblOrdenDesorden }}
            </v-btn>
              <v-btn
              id="btnGalloTapado"
              @click="onBlindRooster"
              class="control-rooster-button"
              elevation="2"
              :disabled="disabledControls"
            >
              {{ lblRooster }}
            </v-btn>
       
        </div>
        <div class="control-row-2">
         
            <v-btn
              v-for="(btn, index) of valueButtons"
              :key="index"
              :class="getButtonClass(btn)"
              @click="onButtonValueClick(index)"
              :disabled="disabledControls"
              elevation="2"
            >
              {{ btn }}
            </v-btn>
             <button
              class="lot-button control-print-button"
              :disabled="disabledPrint"
              @click="onPrintClick"
              elevation="2"
            >
              <v-icon class="control-button-print-icon">mdi-printer</v-icon>
              {{ lblPrint }}
              {{ lblTicket }}
            </button>
            <button
              :disabled="acceptDisabled"
              @click="onAceptarClick"
              class="lot-button control-button-aceptar"
            >
              <v-icon class="control-button-aceptar-icon"
                >mdi-keyboard-return</v-icon
              >
              {{ lblAceptar }}
            </button>
         
           

        <!--   <div class="buttons-col-right">
            <button
              class="lot-button control-print-button"
              :disabled="disabledPrint"
              @click="onPrintClick"
              elevation="2"
            >
              <v-icon class="control-button-print-icon">mdi-printer</v-icon>
              {{ lblPrint }}
              {{ lblTicket }}
            </button>
            <button
              :disabled="acceptDisabled"
              @click="onAceptarClick"
              class="lot-button control-button-aceptar"
            >
              <v-icon class="control-button-aceptar-icon"
                >mdi-keyboard-return</v-icon
              >
              {{ lblAceptar }}
            </button>
          </div> -->
        </div>
     
      
    </div>
  </div>
</template>

<script>
export default {
  name: "ControlPanel",
  data() {
    return {
      lblDisplayNumber: "NUMERO SELECCIONADO",
      lblDisplayBet: "APUESTA",
      lblCancelar: "CANCELAR",
      lblBetDialog: "MONTO DE APUESTA",         
      lblOrdenDesorden: "Revancha",
      lblOrdenUltimos: "Ultimos 2",
      lblRaffles: "Sorteos",
      lblTimes: "Horario",
      lblReventados: "Reventados",
      lblPrint: "Imprimir",
      lblTicket: "Ticket",
      currentPadTagert: "currentNumber",
      lblRooster: "Gallo tapado",
      lblBet: "Monto en",
      disabledMatutino: false,
      drawer: false,
    };
  },

  mounted() {
    this.resetValues();
    this.checkRaffleTime();
    this.toggleActiveRaffleButton("btnFirstDay");
    this.updateRaffle("Orden");
  },
  props: [
    "days",
    "revenge",
    "times",
    "lblAceptar",    
    "lblFirstDay",    
    "lblSecondDay",    
    "betButtons",
    "valueButtons",
    "currency",
    "disabledPrint",
    "acceptDisabled",
    "customBetDisabled",
    "reset",
    "matutinoTime",
    "nocturnoTime",
    "resetCustomBet",
    "currentRaffleDay",
    "currentRaffleTime",
    "alertType",
    "alertText",

    "disabledControls",  
    "disabledDateControls", 
    "disabledBetTypes", 
    "setDefaultDay", 
  ],
  watch: {
    reset(val) {
      this.resetValues();
    },
    resetCustomBet(val) {
      this.resetBet(val);
    },
    currentRaffleDay() {
      this.checkRaffleTime();
    },
    setDefaultDay(val){     
      console.log('setDefaultDay')
       setTimeout(() => {
            this.setDefault();
          }, 50);
     
    },
   
  },

  computed:{
    revengeActive(){
      //console.log('Revenge '+this.revenge)
      return this.revenge;
    }
  },

  filters: {
    addCurrency(val, currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " " + currency;
      }
    },
  },

  methods: {
    getCurrentRaffleDay() {
      return String(this.currentRaffleDay.date).split("/")[0];
    },
    getCurrentRaffleTime() {
      return String(String(this.currentRaffleTime).split("")[0]).toUpperCase();
    },
    getAlertTitleClass() {
      if (this.alertType == "error") {
        return "notifications-alert notification-alert-error";
      } else {
        return "notifications-alert  notification-alert-info";
      }
    },
    onShowControls() {
      this.drawer = true;
    },
    checkRaffleTime() {
      var currentDate = new Date();
      var hours = currentDate.getHours();
      var minutes = currentDate.getMinutes();
      var seconds = currentDate.getSeconds();
      var nowTime = hours + ":" + minutes + ":" + seconds;
      var limitTime = this.matutinoTime + ":00";

      var nowDate = new Date(currentDate.getTime());
      nowDate.setHours(nowTime.split(":")[0]);
      nowDate.setMinutes(nowTime.split(":")[1]);
      nowDate.setSeconds(nowTime.split(":")[2]);

      var limitDate = new Date(currentDate.getTime());
      limitDate.setHours(limitTime.split(":")[0]);
      limitDate.setMinutes(limitTime.split(":")[1]);
      limitDate.setSeconds(limitTime.split(":")[2]);

      if (
        nowDate > limitDate &&
        this.currentRaffleDay.weekday == this.days[0].weekday
      ) {
        this.disabledMatutino = true;

       /*  setTimeout(() => {
          this.onRaffleTimeButtonClick(1);
        }, 50); */
      } else {
        this.disabledMatutino = false;
        setTimeout(() => {
          this.onRaffleTimeButtonClick(0);
        }, 50);
      }
    },
    getDisabledTimeStatus(time) {
      if (String(time).toLowerCase() == "matutino" && this.disabledMatutino) {
        return true;
      } else {
        return false;
      }
    },
    resetBet(val) {
      var betIndex = this.betButtons.indexOf(String(val));
      if (betIndex > -1) {
        this.toggleActiveBetChip("chip-bet-" + betIndex);
      } else {
        this.toggleActiveBetChip("chip-bet-0");
      }
    },
    resetValues() {
      //console.log("Control Panel Reset");
      this.toggleActiveBetChip("chip-bet-0");
      this.toggleActiveRaffle("btnRaffleOption-0");
      this.checkRaffleTime();
    },
    createRipple(event) {
      const button = event.currentTarget;
      const circle = document.createElement("span");

      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      circle.style.width = circle.style.height = `${diameter}px`;
      /*  circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
      circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`; */

      circle.style.left = `${button.clientWidth * 0.5 - diameter * 0.5}px`;
      circle.style.top = `${button.clientHeight * 0.5 - diameter * 0.5}px`;

      circle.classList.add("ripple");

      const ripple = button.getElementsByClassName("ripple")[0];

      if (ripple) {
        ripple.remove();
      }
      button.appendChild(circle);
    },
    onPrintClick(event) {
      this.createRipple(event);
      this.$emit("print");
    },
    getButtonClass(label) {
      if (String(label).toLowerCase() == "borrar") {
        return "control-delete-button";
      } else if (String(label).toLowerCase() == "<") {
        return "control-delete-back-button";
      } else {
        return "control-value-button";
      }
    },
    onButtonBetClick(index) {
      this.toggleActiveBetChip("chip-bet-" + index);
      if (index != "d") {
        this.$emit("update-bet", index);
      } else {
        this.$emit("update-custom-bet");
      }
    },
    onButtonValueClick(index) {
      this.$emit("keyboard-update", index);
    },
    onRaffleButtonClick(index) {
      this.closeDrawer();
      this.toggleActiveRaffle("btnRaffleOption-" + index);
      //this.$emit("on-update-raffle-day", index);
    },
    onRaffleTimeButtonClick(index) {
      this.closeDrawer();
      this.toggleActiveRaffleTime("btnRaffleTimeOption-" + index);
      this.$emit("on-update-raffle-time", index);
    },
    closeDrawer() {
      var ref = this;
      setTimeout(() => {
        ref.drawer = false;
      }, 300);
    },

    onAceptarClick(event) {
      this.createRipple(event);
      this.$emit("on-aceptar-tap");
    },
    onBlindRooster() {
      this.$emit("on-blind-rooster-tap");
    },
    onRevengeClick() {
      this.$emit("on-revenge-tap");
    },
    onFirstDayClick() {
      this.toggleActiveRaffleButton("btnFirstDay");
      this.updateRaffleDay(0);
    },
    onSecondDayClick() {
      this.toggleActiveRaffleButton("btnSecondDay");
      this.updateRaffleDay(1);
    },
    onOrdDesClick() {
      this.toggleActiveRaffleButton("btnOrdDes");
      this.updateRaffle("Ord/Des");
    },
    onUltimosClick() {
      this.toggleActiveRaffleButton("btnUltimos");
      this.updateRaffle("Ultimos");
    },
    updateRaffle(raffle) {
      this.$emit("update-raffle-type", raffle);
    },
    updateRaffleDay(raffle) {
      this.$emit("on-update-raffle-day", raffle);
    },
    toggleActiveRaffleButton(id) {
      var buttons = document.getElementsByClassName("display-button");
      //console.log("toggleActiveRaffleButton " + buttons.length);
      for (let i = 0; i < buttons.length; i++) {
        //console.log("id " + buttons[i].id);
        if (buttons[i].id == id) {
          //console.log('Agregando clase a  '+buttons[i].id)
          document.getElementById(id).classList.add("display-button-active");
        } else {
          buttons[i].classList.remove("display-button-active");
        }
      }
    },

    toggleActiveBetChip(id) {
      var chips = document.getElementsByClassName("bet-chip");
      for (let i = 0; i < chips.length; i++) {
        if (chips[i].id == id) {
          document.getElementById(id).classList.add("bet-chip-active");
        } else {
          chips[i].classList.remove("bet-chip-active");
        }
      }
    },
    toggleActiveRaffle(id) {
      var buttons = document.getElementsByClassName("raffle-option-button");

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          buttons[i].classList.add("raffle-option-active");
        } else {
          buttons[i].classList.remove("raffle-option-active");
        }
      }
    },
    toggleActiveRaffleTime(id) {
      var buttons = document.getElementsByClassName("time-option-button");

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          buttons[i].classList.add("time-option-active");
        } else {
          buttons[i].classList.remove("time-option-active");
        }
      }
    },
     setDefault() {

      let wDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
     
      let weekDay = new Date(yyyy, mm, Number(dd) );

      let todayDayWeek  = wDays[weekDay.getDay()];
      
      if(todayDayWeek === "DOM" || todayDayWeek === "LUN"
      || todayDayWeek === "MAR" || todayDayWeek === "MIE" ){
         this.toggleActiveRaffleButton("btnFirstDay");
      }else if(todayDayWeek === "JUE" || todayDayWeek === "VIE"  || todayDayWeek === "SAB"){
         this.toggleActiveRaffleButton("btnSecondDay"); 
      }

      //console.log('WeekDay HOY '+wDays[weekDay.getDay()])
    
    }
  },
};
</script>