import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Selector from '../views/Selector.vue'
import GameNuevosTiempos from '../views/GameNuevosTiempos.vue'
import GameTresMonazos from '../views/GameTresMonazos.vue'
import GameLotto from '../views/GameLotto.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,

  },
  {
    path: '/selector',
    name: 'selector',
    component: Selector,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nuevosTiempos',
    name: 'nuevosTiempos',
    component: GameNuevosTiempos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tresMonazos',
    name: 'tresMonazos',
    component: GameTresMonazos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lotto',
    name: 'lotto',
    component: GameLotto,
    meta: {
      requiresAuth: true
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  /* base: process.env.BASE_URL, */
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  try {
    if (requiresAuth) {
      if (store.getters.user) {
        next();
      } else {
        if (document.getElementById("user")) {
          var element = document.getElementById("user");
          var user = element.value;
          element.parentNode.removeChild(element);
          //console.log('Router ' + user);
          if (user === 'AnonymousUser') {
            router.push({ path: '/' });
          } else {
            next();
          }
        } else {
          //next({ path: '/' });
          router.push({ path: '/' });
        }
      }
    } else {
      next();
    }
  } catch (error) {
    console.log('Router error: ' + error);
  }
})

export default router
