
export default class Printer {

    printerInitialized = 0;
    CONSTANT_ALIGNMENT_LEFT   = 0;
    CONSTANT_ALIGNMENT_MIDDLE = 1;
    CONSTANT_ALIGNMENT_RIGHT  = 2;
    printerSequence = [];
    endPrint = undefined;

    constructor() 
    {
        this.printerInitialized = 0;
        this.printerSequence = [];
    }

    

    setAlignment( alignmentMode, callback )
    {
        sunmiInnerPrinter.setAlignment( alignmentMode, callback, function(e) {
             console.log("ERROR on set Alignment:", e);
            } );
    }


   

    activated(){
        if(this.printerInitialized === 1){
            return true;
        }else{
            return false;
        } 
    }

    initializePrinter(callBack,reject){
        //console.log('initializePrinter ')
        if(this.printerInitialized === 0 )
        {
           try {
                sunmiInnerPrinter.printerInit( function() {
                    //console.log('initializePrinter succes');
                    //alert('initializePrinter succes')
                    this.printerInitialized = 1; //Just avoid sending again the same thing to the printer.
                    if(callBack){
                        callBack();
                    }
                   
                }, function(e){
                    //console.log('initializePrinter initialize error' );
                   //alert('Printer Initialize error '+e)
                   this.printerInitialized = 0; 
                   if(reject){
                    reject();
                    }
                } );
           } catch (error) {
               console.log('initializePrinter '+error);
               this.printerInitialized = 0;
               //alert('Printer Initialize error '+error)
               if(reject){
                reject();
                }
           } 
           
           
        }
    }

    printTicket(ticketData,callBack,reject){


        //console.log('printTicket callBack '+callBack);

        //Caracteres totales en ancho con fuente en 24: 32
        try {
            
        
            this.endPrint = callBack;

            let fontSize = ticketData.fontSize;
            let devisor = '--------------------------------';

            this.printerSequence = [];

        
            
            this.printerSequence.push({name:'setFontSize',params:{fontSize:fontSize}});
            this.printerSequence.push({name:'setAlignment',params:{align:1}});
            this.printerSequence.push({name:'lineWrap',params:{lines:2}});
            this.printerSequence.push({name:'printBitmap',params:{imgData:ticketData.logoData,imgWidth:ticketData.logoWidth,imgHeight:ticketData.logoHeight}});
            this.printerSequence.push({name:'lineWrap',params:{lines:2}});
            this.printerSequence.push({name:'setAlignment',params:{align:0}});
            this.printerSequence.push({name:'printString',params:{text:devisor}});
            this.printerSequence.push({name:'printString',params:{text:'Sorteos:'}});  

            if(ticketData.decreseFontNumbers > 0){
                this.printerSequence.push({name:'setFontSize',params:{fontSize:fontSize - ticketData.decreseFontNumbers}});            
            }  

            for (let i = 0; i < ticketData.numbers.length; i++) {   
                this.printerSequence.push({name:'printString',params:{text:ticketData.numbers[i]}});               
            } 

            if(ticketData.decreseFontNumbers > 0){   
                this.printerSequence.push({name:'setFontSize',params:{fontSize:fontSize}});
            }

            this.printerSequence.push({name:'printString',params:{text:devisor}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.totalAmount}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.numbersAmount}});
            this.printerSequence.push({name:'printString',params:{text:devisor}});        
            this.printerSequence.push({name:'setFontSize',params:{fontSize: fontSize + 2 }});
            this.printerSequence.push({name:'setAlignment',params:{align:1}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.message}});
            this.printerSequence.push({name:'setAlignment',params:{align:0}});
            this.printerSequence.push({name:'setFontSize',params:{fontSize: fontSize }});
            this.printerSequence.push({name:'printString',params:{text:devisor}});  
            this.printerSequence.push({name:'printString',params:{text:ticketData.gameDate}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.terminalData}});
            this.printerSequence.push({name:'lineWrap',params:{lines:1}});
            this.printerSequence.push({name:'setAlignment',params:{align:1}});
            this.printerSequence.push({name:'printQRCode',params:{qrValue:ticketData.qrValue,qrSize:4,qrLevel:3}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.qrValue}});    
            this.printerSequence.push({name:'lineWrap',params:{lines:1}});    
            this.printerSequence.push({name:'setAlignment',params:{align:0}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.printDate}}); 
            this.printerSequence.push({name:'lineWrap',params:{lines:3}});


             
          /*   this.printerSequence.push({name:'setFontSize',params:{fontSize:fontSize}});
            this.printerSequence.push({name:'setAlignment',params:{align:1}});
            this.printerSequence.push({name:'lineWrap',params:{lines:2}});
            this.printerSequence.push({name:'printBitmap',params:{imgData:ticketData.logoData,imgWidth:150,imgHeight:150}});
            this.printerSequence.push({name:'lineWrap',params:{lines:2}});
            this.printerSequence.push({name:'printString',params:{text:devisor}});
            this.printerSequence.push({name:'printColumnsText',params:{values:ticketData.titlesRow,widths:ticketData.columnsWidth,aligns:ticketData.columnsAlign}});
            
            for (let i = 0; i < ticketData.numbers.length; i++) {   
                this.printerSequence.push({name:'printColumnsText',params:{values:ticketData.numbers[i],widths:ticketData.columnsWidth,aligns:ticketData.columnsAlign}});
            }    
            
            this.printerSequence.push({name:'printString',params:{text:devisor}});
            this.printerSequence.push({name:'printColumnsText',params:{values:ticketData.totalAmount,widths:[6,26],aligns:[0,2]}});
            this.printerSequence.push({name:'printColumnsText',params:{values:ticketData.numbersAmount,widths:[8,24],aligns:[0,2]}});
            this.printerSequence.push({name:'printString',params:{text:devisor}});        
            this.printerSequence.push({name:'setFontSize',params:{fontSize: fontSize + 2 }});
            this.printerSequence.push({name:'setAlignment',params:{align:1}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.message}});
            this.printerSequence.push({name:'setFontSize',params:{fontSize: fontSize }});
            this.printerSequence.push({name:'printString',params:{text:devisor}});  
            this.printerSequence.push({name:'printColumnsText',params:{values:ticketData.gameDate,widths:[15,17],aligns:[0,2]}});
            this.printerSequence.push({name:'printColumnsText',params:{values:ticketData.terminalData,widths:[9,23],aligns:[0,2]}});
            this.printerSequence.push({name:'lineWrap',params:{lines:1}});
            this.printerSequence.push({name:'setAlignment',params:{align:1}});
            this.printerSequence.push({name:'printQRCode',params:{qrValue:ticketData.qrValue,qrSize:4,qrLevel:3}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.qrValue}});        
            this.printerSequence.push({name:'setAlignment',params:{align:0}});
            this.printerSequence.push({name:'printString',params:{text:ticketData.printDate}}); 
            this.printerSequence.push({name:'lineWrap',params:{lines:3}}); */

        
            this.nextPrintLine();

        } catch (error) {
            if(reject){
                reject();
            }
            alert('Print Error - printTicket : '+error); 
        }

        
    }


    nextPrintLine(){

        try {
            if(this.printerSequence.length > 0){
            
                let nextFunction = this.printerSequence.splice(0,1)[0];            
                this.executePrintFunction(nextFunction.name,nextFunction.params);
    
            }else{
                
                if(this.endPrint){                
                    this.endPrint();
                }
            }
            
        } catch (error) {
            alert('Print Error - nextPrintLine : '+error);
        }
      
       
    }

    executePrintFunction(functionName, params){
        try {           
            var printerClassRef = this;
            switch (functionName) {
                case 'setFontSize':
                   
                    sunmiInnerPrinter.setFontSize(params.fontSize,function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;
                case 'setAlignment':
                    
                    sunmiInnerPrinter.setAlignment(params.align,function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;
                case 'lineWrap':
                  
                    sunmiInnerPrinter.lineWrap(params.lines,function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;
                case 'printBitmap':
                   
                    sunmiInnerPrinter.printBitmap(params.imgData,params.imgWidth,params.imgHeight,function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;               
                case 'printString':
                    
                    sunmiInnerPrinter.printString(params.text+'\n',function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;
                case 'printColumnsText':
                   
                    sunmiInnerPrinter.printColumnsText(params.values,params.widths,params.aligns,function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;
                case 'printQRCode':
                   
                    sunmiInnerPrinter.printQRCode(params.qrValue,params.qrSize,params.qrLevel,function(){
                        printerClassRef.nextPrintLine();
                    });
                    break;
            
                default:
                    break;
            }
            
        } catch (error) {
            alert('Printer error - executePrintFunction: '+error);
        }
        
    }
    

    getPrinterStatus(){
        return sunmiInnerPrinter.printerGetStatus();
    }
   
}