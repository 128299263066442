<template>
  <div class="game-container">
        <div class="toPrint">
           <img
            id="ticketImage"
            alt="Logo"
            style="max-width:100%;"
            src="/static/dist/img/lottoLogo.6e8555b5.png"
            
            
          />

          <img
            id="apkTicketImageLt"
            alt="Logo"
            style="max-width:100%;"
            src="/static/dist/img/lottoBNW.png"
           
            
          />

        <canvas id="apkLogoCanvasLt" width="150" height="137" />

          
          <div id="printTicket" class="print-ticket">
            

            <table class='table-ticket-numbers'>
                <tr>
                  <td class="index-column font-weight-bold">#</td>
                  <td class="numbers-column font-weight-bold">Números</td>
                  <td class="date-column font-weight-bold">Fecha</td>
                  <td class="bet-column font-weight-bold">Monto</td>
                <tr>
                <tr  v-for="(num, index) of selectedNumbers"
                :key="index">
                  <td class="index-column">{{ getIndex(index) }}</td>
                  <td class="numbers-column"><span class="ticket-number" v-for="(val,e) in num.numbers" :key="e" >{{ getTicketNumber(val)}}</span></td>
                  <td class="date-column">{{ num.date }}</td>
                  <td class="bet-column"><span>{{ getRaffleType(num)}}</span>  {{ num.bet | addCurrency(currency, true) }}</td>
                </tr>
              
            </table>
            <table class="table-ticket-totals">
                <tr>
                  <td class="total-label">
                    Monto Total:
                  </td>
                  <td class="total-amount">
                    {{ getTotal | addCurrency(currency, true) }}
                  
                  </td>
                </tr>
                <tr>
                  <td class="total-label">
                    Cantidad de sorteos:
                  </td>
                  <td class="total-amount">
                    {{ selectedNumbers.length }}
                  </td>
                </tr>
              
            </table>
            <div class="good-luck-message">
              <p>¡ MUCHA SUERTE !</p>
            </div>
            <table class="table-ticket-info">
                <tr>
                  <td class="total-label">
                    Fecha jugada:
                  </td>
                  <td class="total-amount">
                    {{ getPrintDate() }}
                  </td>
                </tr>
                <tr>
                  <td class="total-label">
                    Terminal:
                  </td>
                  <td class="total-amount">
                    1208-001
                  </td>
                </tr>
            </table>
            <qrcode-vue class="qr-ticket" :value="qrValue" :size="qrSize" :level="qrLevel" />
            <p>{{qrValue}}</p>
            <p>Fecha de impresión: {{getPrintDate()}}</p>
          </div>
        </div>
    <div v-if="!portrait" class="landscape">
      <v-dialog  persistent v-model="printDialog" width="50%">
    
        <div v-if="printing"  class="printing-card">
          <v-icon class="print-icon">mdi-printer</v-icon> 
          <p>Imprimiendo</p>     
          <div class="stage">
              <div class="dot-flashing"></div>
          </div>
        </div>
        <v-card class="print-dialog-lt">
          <div class="dialog-title">
            <p class="dialog-label">{{ lblPrintDialogTitle }}</p>
          </div>

          <div class="info-panel-display">
         
            <div
              v-for="(num, index) of selectedNumbers"
              :key="index"
              class="info-panel-number"
            >
              <div class="number-container">
                <div class="index">{{ getIndex(index) }}</div>
                
                <div v-for="(val,e) in num.numbers" :key="e" class="number">{{ val}}</div>
              </div>

              <div  class="type">                
                  <div class="date">{{getDate(num) }}</div>
              </div>
            

            
              <div class="bet">
                <span>{{ getRaffleType(num)}}</span>  {{ num.bet | addCurrency(currency, true) }}
               
              </div>
             
             
            </div>
            
          </div>
          
      
       
          <v-divider class="dialog-divider"></v-divider>
          <div class="resumen-dialog">
            <p class="dialog-resumen-title">{{ lblResumeDialogtitle }}</p>
            <!-- <p class="dialog-resumen-totals">{{ lblDialogRafflesTimes }}</p>
          <p class="dialog-resumen-value">{{ getRaffleTimes }}</p>
          <p class="dialog-resumen-totals">{{ lblDialogRafflesDays }}</p>
          <p class="dialog-resumen-value">{{ getRaffleDays }}</p> -->
            <p class="dialog-resumen-totals">
              {{ lblDialogTotal | addCurrency(currency, false) }} {{ getTotal }}
            </p>
            <!-- <p class="dialog-resumen-value">{{ getTotal }}</p> -->
          </div>
          <div class="controls-dialog">
            <v-btn color="error" class="button-dialog" @click="onDialogCancel">
              {{ lblCancelar }}
            </v-btn>
            <v-btn
              color="success"
              class="button-dialog"
              @click="onDialogAccept"
            >
              {{ lblAceptar }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div class="game-col-1">
        <div class="info-panel-container">
          <info-panel
            ref="info"
            :numbers="selectedNumbers"
            :currency="currency"
            :totalBet="getTotal"
            :raffleDays="getRaffleDays"
            :raffleTimes="getRaffleTimes"
            v-on:clear-numbers="onClear"
            v-on:delete-number="onDelete"
          />
        </div>
      </div>
      <div class="game-col-2">
        <div class="display-panel-container">
          <display-panel
            ref="displayPanel"            
            :firstNumber="firstNumber"
            :secondNumber="secondNumber"
            :thirdNumber="thirdNumber"
            :fourthNumber="fourthNumber"
            :fifthNumber="fifthNumber"
            :amountBet="amountBet"
            :currency="currency"
            :showBetConfirm="showBetConfirm"
            :disabledConfirmarBet="disabledConfirmarBet"
            :disabledReventado="disabledReventado"
            :reset="reset"
            :alertType="alertType"
            :alertText="alertText"
            :reventadoAmountBet="reventadoAmountBet"
            v-on:update-raffle-type="onUpdateRaffleType"
            v-on:update-reventado="onUpdateRevetando"
            v-on:on-confirmar-bet="onConfirmarBet"
            v-on:on-bet="onBet"
            v-on:on-reventado-bet="onReventadoBet"
          />
        </div>
        <div class="control-panel-container">
          <control-panel
            :days="days"
            :times="times"
            :revenge="revenge"
            :valueButtons="valueButtons"
            :betButtons="betButtons"
            :currency="currency"
            :reset="resetDefault"
            :setDefaultDay="defaultDay"
            :lblFirstDay="times[0]"
            :lblSecondDay="times[1]"
            :resetCustomBet="resetCustomBet"
            :acceptDisabled="acceptDisabled"
            :disabledControls="disabledControls"
            :disabledBetTypes="disabledBetTypes"
            :matutinoTime="MATUTINO_TIME"
            :lblAceptar="controlsLabelAceptar"
            :currentRaffleDay="currentRaffleDay"
            :disabledPrint="getPrintDisabledStatus()"
            v-on:keyboard-update="onKeyboardUpdate"
            v-on:update-bet="onUpdateBet"
            v-on:update-custom-bet="onUpdateCustomBet"
            v-on:on-update-raffle-day="onUpdateRaffleDay"
            v-on:on-update-raffle-time="onUpdateRaffleTime"
            v-on:on-revenge-tap="onRevenge"
            v-on:on-blind-rooster-tap="onBlindRooster"
            v-on:on-aceptar-tap="onAceptar"
            v-on:print="onPrint"
          />
        </div>
       <div class="footer">
          <p class="footer_text">
            Powered by 
          </p>
           <img
           
            alt="Logo"
            style="max-width:100%;"
            class="logo-footer"
            src="/static/dist/img/logo_footer.svg"
           
            
          />
          <p class="footer_text">{{ lblFooter }}</p>
        </div>
      </div>
    </div>
    <div v-if="portrait" class="portrait">
      <v-dialog persistent v-model="printDialog" width="100%">
      
        <div v-if="printing"  class="printing-card">
          <v-icon class="print-icon">mdi-printer</v-icon> 
          <p>Imprimiendo</p>     
          <div class="stage">
              <div class="dot-flashing"></div>
          </div>
        </div>
        <v-card class="print-dialog-portrait-lt">
          <div class="dialog-title">
            <p class="dialog-label">{{ lblPrintDialogTitle }}</p>
          </div>

          <div class="info-panel-display">
            <div
              v-for="(num, index) of selectedNumbers"
              :key="index"
              class="info-panel-number"
            >
              <div class="number-container">
                <div class="index">{{ getIndex(index) }}</div>
                  <div v-for="(val,e) in num.numbers" :key="e" class="number">{{ val}}</div>
              </div>

              <div  class="type">              
                <div class="date">{{getDate(num) }}</div>
              </div>
            

              <div class="bet">
                <span>{{ getRaffleType(num)}}</span>  {{ num.bet | addCurrency(currency, true) }}
               
              </div>
            </div>
          </div>
          <v-divider class="dialog-divider"></v-divider>
          <div class="resumen-dialog">
            <p class="dialog-resumen-title">{{ lblResumeDialogtitle }}</p>
            <!-- <p class="dialog-resumen-totals">{{ lblDialogRafflesTimes }}</p>
          <p class="dialog-resumen-value">{{ getRaffleTimes }}</p>
          <p class="dialog-resumen-totals">{{ lblDialogRafflesDays }}</p>
          <p class="dialog-resumen-value">{{ getRaffleDays }}</p> -->
            <p class="dialog-resumen-totals">
              {{ lblDialogTotal | addCurrency(currency, false) }} {{ getTotal }}
            </p>
            <!-- <p class="dialog-resumen-value">{{ getTotal }}</p> -->
          </div>
          <div class="controls-dialog">
            <v-btn color="error" class="button-dialog" @click="onDialogCancel">
              {{ lblCancelar }}
            </v-btn>
            <v-btn
              color="success"
              class="button-dialog"
              @click="onDialogAccept"
            >
              {{ lblAceptar }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="showPdf" width="500">
        <pdf v-if="showPdf" :pdf="pdfUrl"></pdf>
        <v-btn>Cerrar</v-btn>
      </v-dialog> -->
      <div class="portrait-display-panel-container">
        <display-panel-portrait
          ref="displayPanelPortrait"
          :firstNumber="firstNumber"
          :secondNumber="secondNumber"
          :thirdNumber="thirdNumber"
          :fourthNumber="fourthNumber"
          :fifthNumber="fifthNumber"
          :currentNumber="currentNumber"
          :portraitFirstImage="portraitFirstImage"
          :portraitSecondImage="portraitSecondImage"
          :portraitThirdImage="portraitThirdImage"
          :portraitFourthImage="portraitFourthImage"
          :portraitFifthImage="portraitFifthImage"
          :amountBet="amountBet"
          :currency="currency"
          :showBetConfirm="showBetConfirm"
          :disabledConfirmarBet="disabledConfirmarBet"
          :disabledReventado="disabledReventado"
          :reset="reset"
          :currentRaffleType="currentRaffleType"
          :reventadoAmountBet="reventadoAmountBet"
          v-on:update-reventado="onUpdateRevetando"
          v-on:on-confirmar-bet="onConfirmarBet"
          v-on:on-bet="onBet"
          v-on:on-reventado-bet="onReventadoBet"
        />
      </div>
      <div class="portrait-info-panel-container">
        <info-panel-portrait
          ref="infoPortrait"
          :numbers="selectedNumbers"
          :currency="currency"
          :totalBet="getTotal"
          :raffleDays="getRaffleDays"
          :raffleTimes="getRaffleTimes"          
          v-on:clear-numbers="onClear"
          v-on:delete-number="onDelete"
        />
      </div>
      <div class="portrait-control-panel-container">
        <control-panel-portrait
          :days="days"
          :times="times"
          :revenge="revenge"
          :valueButtons="valueButtons"
          :betButtons="betButtons"
          :currency="currency"
          :alertType="alertType"
          :alertText="alertText"
          :lblAceptar="controlsLabelAceptar"
          :setDefaultDay="defaultDay"
          :disabledControls="disabledControls"
          :disabledBetTypes="disabledBetTypes"
          :lblFirstDay="this.times[0]"
          :lblSecondDay="this.times[1]"
          :reset="resetDefault"
          :resetCustomBet="resetCustomBet"
          :acceptDisabled="acceptDisabled"
          :matutinoTime="MATUTINO_TIME"
          :nocturnoTime="NOCTURNO_TIME"
          :currentRaffleDay="currentRaffleDay"
          :currentRaffleTime="currentRaffleTime"
          :disabledPrint="getPrintDisabledStatus()"
          v-on:keyboard-update="onKeyboardUpdate"
          v-on:update-bet="onUpdateBet"
          v-on:update-custom-bet="onUpdateCustomBet"
          v-on:on-update-raffle-day="onUpdateRaffleDay"
          v-on:on-update-raffle-time="onUpdateRaffleTime"
          v-on:on-blind-rooster-tap="onBlindRooster"
          v-on:on-revenge-tap="onRevenge"
          v-on:on-aceptar-tap="onAceptar"
          v-on:print="onPrint"
          v-on:update-raffle-type="onUpdateRaffleType"
        />
      </div>
    </div>
  </div>
</template>
<script>
import "../assets/css/lotto.scss";
import InfoPanel from "../components/lotto/InfoPanelLT.vue";
import DisplayPanel from "../components/lotto/DisplayPanelLT.vue";
import ControlPanel from "../components/lotto/ControlPanelLT.vue";
import InfoPanelPortrait from "../components/lotto/InfoPanelPortraitLT.vue";
import DisplayPanelPortrait from "../components/lotto/DisplayPanelPortraitLT.vue";
import ControlPanelPortrait from "../components/lotto/ControlPanelPortraitLT.vue";

import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import QrcodeVue from 'qrcode.vue'
import TicketData from "../helpers/ticketData.js"


import { mapState } from "vuex";

export default {
  name:'GameLotto',
  components: {
    "info-panel": InfoPanel,
    "display-panel": DisplayPanel,
    "control-panel": ControlPanel,
    "info-panel-portrait": InfoPanelPortrait,
    "display-panel-portrait": DisplayPanelPortrait,
    "control-panel-portrait": ControlPanelPortrait,
    QrcodeVue
  },
  data() {
    return {
      game: null,
      printing:false,
      MAX_BET: 2000000,
      MIN_BET: 100,
      MAX_NUMBERS: 10,
      MATUTINO_TIME: "12:00",
      NOCTURNO_TIME: "19:00",
      currency: "₡",
      selectedNumbers: [],
      valueButtons: [],
      betButtons: [],
      days: [],
      times: [],
      currentNumber: "",
      keyboardNumber: "",
      firstNumber: "",
      secondNumber: "",
      thirdNumber: "",
      fourthNumber: "",
      fifthNumber: "",     
      currentRaffleType: "",
      currentRaffleDay: "",
      currentRaffleTime: "",
      currentPadTagert: "",
      reventado: false,
      reset: false,
      resetDefault: false,
      amountBet: 0,
      revengeAmountBet: 0,
      reventadoAmountBet: 0,
      alertType: "info",
      alertText: "",
      printDialog: false,
      lblAceptar: "Aceptar",
      lblCancelar: "Cancelar",
      lblPrintDialogTitle: "¿Desea imprimir el ticket?",
      lblDialogRafflesDays: "Dias: ",
      lblDialogRafflesTimes: "Horario: ",
      lblDialogTotal: "Total en ",
      lblResumeDialogtitle: "Resumen:",
      lblFooter: "",
      acceptDisabled: false,
      disabledConfirmarBet: true,
      disabledReventado: false,
      duplicateNumber: false,
      blindRooster: false,
      revenge: false,
      showBetConfirm: false,
      resetCustomBet: "",
      previousBet: "",
      printPdf: true,
      portrait: false,
      showPdf: false,

      controlsLabelAceptar:'Ingresar',
      blindCounter: 0,
      totalSteps:2,
      currentStep:1,
      currentRaffle:{numbers:[-1,-1,-1,-1,-1],weekDay:'',date:'',type:'',bet:0},
      BET_TYPE_STATE:'betTypeState',     
      RAFFLE_NUMBER_STATE:'raffleNumberState',         
      RAFFLE_DATE_STATE:'raffleDateState',         
      INSERT_RAFFLE_STATE:'insertDateState',         
      currentState:null,
      disabledBetTypes:false,      
      disabledControls:false,      
      disabledDateControls:false,
      defaultDay:false,

      portraitFirstImage:true,
      portraitSecondImage:true,
      portraitThirdImage:true,
      portraitFourthImage:true,
      portraitFifthImage:true,
      qrValue:'125646-45616465',
      qrSize:130,
      qrLevel:'H',
      weekDays:[]
      
    };
  },
 
  created() {
    window.addEventListener("resize", this.resolutionUpdated);
    this.resolutionUpdated();
    this.currentPadTagert = "currentNumber";
    if(this.portrait){
      this.valueButtons = [
      "7",
      "8",
      "9",
      "4",
      "5",
      "6",
      "1",
      "2",
      "3",
      "<",      
      "0",   
      "BORRAR",
    ];
    }else{

   
      this.valueButtons = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",      
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "<",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "BORRAR",
      ];
     }
    this.betButtons = ["100", "200", "300", "400", "500", "1000", "2000"];
    this.times = ["Mie", "Sab"];
    this.days = ["Mie", "Sab"];
    this.amountBet = 600;
    this.revengeAmountBet = 400;   
    this.currentNumber = "first";
    this.alertText = "";
    this.currentRaffleType = "Orden";
    this.lblFooter = "v" + this.version;
    this.currentRaffleTime = this.times[0];

    this.currentState = this.RAFFLE_DATE_STATE;
   
    this.setDates();
    this.checkDefault();
    
  },
  destroyed() {
    window.removeEventListener("resize", this.resolutionUpdated);
  },
  mounted() {
    console.log('Lotto mounted ',this.onApk ,this.apkPrinterReady );
    if(this.onApk && !this.apkPrinterReady){
        this.$printer.initializePrinter(this.onApkPrinterReady,this.onApkPrinterReject);
    }
    this.$store.dispatch("setTitle", "Lotto");
    this.setRaffleAlert();
    if (document.getElementById("version")) {
      var element = document.getElementById("version");
      var version = element.value;
      this.$store.dispatch("setVersion", version);
      //console.log('Version '+this.version)
      this.lblFooter = "v" + this.version;
      element.parentNode.removeChild(element);
    }
    if (document.getElementById("printPdf")) {
      var element = document.getElementById("printPdf");
      if (element.value == "True") {
        this.printPdf = true;
      } else {
        this.printPdf = false;
      }
      element.parentNode.removeChild(element);
    }
    this.defaultDay = !this.defaultDay; 
    //this.checkStep();
  
   /*  for (let i = 0; i < this.weekDays.length; i++) {         
      console.log('WeekDay '+this.weekDays[i].weekDay+' '+this.weekDays[i].date)
    } */

    this.clearCurrentRaffle();
  },

  computed: {
    ...mapState({
      pdfUrl: (state) => state.pdfUrl,
      version: (state) => state.version,
      onApk: (state) => state.onApk,
      apkPrinterReady: (state) => state.apkPrinterReady,
    }),
    getTotal() {
      var total = 0;
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        total += Number(this.selectedNumbers[i].bet);
       
      }

      if (total == 0) {
        return "";
      } else {
        return total;
      }
    },
    getRaffleDays() {
      var days = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var day = String(this.selectedNumbers[i].raffleDay).toLowerCase();
        day = day.charAt(0).toUpperCase() + day.slice(1);
        if (days.indexOf(day) < 0) {
          days.push(day);
        }
      }
      if (days.length == 0) {
        return "";
      } else {
        return days.toString();
      }
    },
    getRaffleTimes() {
      var times = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var time = String(this.selectedNumbers[i].raffleTime).toLowerCase();
        time = time.charAt(0).toUpperCase() + time.slice(1);
        if (times.indexOf(time) < 0) {
          times.push(time);
        }
      }
      if (times.length == 0) {
        return "";
      } else {
        return times.toString();
      }
    },
    getRaffles() {
      var raffes = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var type = String(this.selectedNumbers[i].type).toLowerCase();
        var raffleType = "";
        //Para pasarle al ticket la abreviacion
        switch (String(type).toLowerCase()) {
          case "orden":
            raffleType = "ORD";
            break;
          case "desorden":
            raffleType = "DES";
            break;
          case "ord/des":
            raffleType = "ORD/DES";
            break;
          case "ultimos":
            raffleType = "ULT";
            break;

          default:
            break;
        }
        if (raffes.indexOf(raffleType) < 0) {
          raffes.push(raffleType);
        }
      }

      return raffes;
    },
  },
  filters: {
    capitalized(val) {
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    addCurrency(val, currency, before) {
      if (val != "0" && val != "") {
        if (before) {
          return currency + " " + val;
        } else {
          return val + " " + currency;
        }
      } else {
        return val;
      }
    },
  },
  methods: {

     onApkPrinterReady(){
      console.log('onApkPrinterReady')
      this.$store.dispatch("setApkPrinterReady", true);
    },
    onApkPrinterReject(){
      console.log('onApkPrinterReject')
      this.$store.dispatch("setApkPrinterReady", false);
    },
   
     getDate(num){
      return String(num.weekDay).toUpperCase()+' '+num.date;
    },
    getTicketNumber(val){
      if(val.length === 1){
        return '0'+val;
      }else{
        return val;
      }
    },

    onCreateTicket(){
       // console.log('onCreateTicket '+document.getElementById("ticket"))    
       
       if(this.onApk){
          try {

            this.apkPrint();

          } catch (error) {

            alert('Print Error '+error);

          } 
        }else{
         
          this.printing = true;
          const minDocHeight = 80;
          const maxDocHeight = 110;
          let docWidth = 55; 
          let docHeight = maxDocHeight; 

          if(this.selectedNumbers.length < this.MAX_NUMBERS){
            docHeight = minDocHeight + (this.selectedNumbers.length  * 3);
          }
  
          let doc = new jsPDF('p', 'mm', [docHeight, docWidth]); //310mm high 55mm wide
          let ref = this;
          let scaleCanvas = null;      
          
          
          if(!this.portrait){
            scaleCanvas = 1.5;
          }
        
       
          html2canvas(document.getElementById("printTicket"),{scale:scaleCanvas, width: document.getElementById("printTicket").offsetWidth,
          height: document.getElementById("printTicket").offsetHeight}).then(function(canvas) {

              const imgData = canvas.toDataURL('image/png');   
              
            
              

              const pageWidth = doc.internal.pageSize.getWidth();
              const pageHeight = doc.internal.pageSize.getHeight();

              const widthRatio = pageWidth / canvas.width;
              const heightRatio = pageHeight / canvas.height;
              const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

              const canvasWidth = canvas.width * ratio;
              const canvasHeight = canvas.height * ratio;

              const marginX = (pageWidth - canvasWidth) / 2;
              const marginY = (pageHeight - canvasHeight) / 2;

              let imageHeight = pageWidth * 0.3;
              let imageWidth = pageWidth * 0.320;
              
            
              const imageMarginX = (pageWidth - imageWidth) / 2;

              const fileName = 'Lotto-'+ref.getPrintDate()+'.pdf';
              const gap = 1;
              
              
              doc.addImage(document.getElementById("ticketImage"), 'PNG', imageMarginX, gap,imageWidth,imageHeight);
              doc.addImage(imgData, 'PNG', 0, imageHeight + gap,canvasWidth,canvasHeight);

              
            
              doc.save(fileName);

              setTimeout(() => {
                  ref.printing = false;
                  ref.printDialog = false;          
                  ref.onClear();
              }, 1000);

            
            
          }
          );
        }

    },
    apkPrint(){


      //console.log('sendImageToPrint')

      this.printing = true;

      let c = document.getElementById("apkLogoCanvasLt");
      let ctx = c.getContext("2d");
      let img = document.getElementById("apkTicketImageLt");
      
      ctx.drawImage(img, 0, 0,document.getElementById("apkTicketImageLt").offsetWidth,document.getElementById("apkTicketImageLt").offsetHeight);
      let logoData = c.toDataURL('image/png').split(';base64,')[1]


      let ticket =  new TicketData();

    
      ticket.logoData                 = logoData;     
      ticket.totalAmount              = 'Total: '+this.currency+this.getTotal;
      ticket.numbersAmount            = 'Cantidad de sorteos: '+this.selectedNumbers.length;
      ticket.gameDate                 = 'Fecha jugada: '+this.getPrintTicketDate();
      ticket.terminalData             = 'Terminal: 1208-001';
      ticket.qrValue                  = this.qrValue;
      ticket.printDate                = 'Fecha de imp. '+this.getPrintTicketDate();
      ticket.message                  = '¡ MUCHA SUERTE !';
      ticket.logoWidth                = 150;
      ticket.logoHeight               = 137;
      ticket.fontSize                 = 23;
      ticket.decreseFontNumbers       = 1;

      
      ticket.numbers = [];

      for (let i = 0; i < this.selectedNumbers.length; i++) {

        let raffle = this.selectedNumbers[i];      
        let bet = String(this.currency + raffle.bet);
        let date = String(raffle.weekDay).charAt(0)+' '+String(raffle.date).replace(/\s/g, '');
        let nums = '';
        for (let e = 0; e < raffle.numbers.length; e++) {
          if(raffle.numbers[e].length === 1){
            raffle.numbers[e] = '0'+raffle.numbers[e];
          }
          if(e > 0){
            nums += ' '+raffle.numbers[e];
          }else{
            nums += raffle.numbers[e];
          }
          
          
        }       

      

        if(raffle.revenge){
          if(raffle.blindRooster){
            let betLength = String(this.currency + raffle.bet).length;
            bet = 'RGT ';
            bet =  bet.padEnd(9 - betLength);
            bet += String(this.currency + raffle.bet);
           
          }else{
            let betLength = String(this.currency + raffle.bet).length;
            bet = 'R ';
            bet =  bet.padEnd(9 - betLength);
            bet += String(this.currency + raffle.bet);
          }
          
        }else if(raffle.blindRooster){
           let betLength = String(this.currency + raffle.bet).length;
           bet = 'GT ';
           bet =  bet.padEnd(9 - betLength);
           bet += String(this.currency + raffle.bet);
          
        }   
        
        if(!raffle.revenge && !raffle.blindRooster){
            bet =  bet.padStart(9);
        }
       
       
             

        let row = this.getIndex(i)+' '+nums+' '+date+' '+bet;
        ticket.numbers.push(row);
        
      }

     
      
      try {

        let ref = this;
        this.$printer.printTicket(ticket,function(){
            //console.log('Print end');
            ref.printDialog = false;          
            ref.onClear();
            ref.printing = false;
        },function(){
            ref.printing = false;
        });

        
      } catch (error) {
        this.printing = false;
        alert('Print Error '+error);
         
      }

    },

    getRaffleType(raffle){
      if(raffle.blindRooster.length > 0 && raffle.revenge.length > 0){
        return  `(${raffle.blindRooster }) (${raffle.revenge })`
      }else if(raffle.blindRooster.length > 0 && raffle.revenge.length === 0){
        return  `(${raffle.blindRooster })`
      }else if(raffle.blindRooster.length === 0 && raffle.revenge.length > 0){
        return  `(${raffle.revenge })`
      }
    },
    checkStep(){
      
      if(this.currentStep === 1){
        switch (this.currentNumber) {
          case 'first':    
            if(this.firstNumber.length > 0){
              this.alertText = "Presione SIGUIENTE";
              this.alertType = 'info';
              this.controlsLabelAceptar = 'Siguiente';
            } else{
              if(!this.portrait){
                this.alertText = "Seleccione 5 números y presione INGRESAR";
              }else{
                this.alertText = "Ingrese un Nº del 0 al 40 y presione SIGUIENTE";
              } 
              this.alertType = 'info';
            }      
              
            break;
          case 'second':    
            if(this.secondNumber.length > 0){
              this.alertText = "Presione SIGUIENTE";
              this.alertType = 'info';
              this.controlsLabelAceptar = 'Siguiente';
            } else{
               if(!this.portrait){
                this.alertText = "Seleccione 5 números y presione INGRESAR";
              }else{
                this.alertText = "Ingrese un Nº del 0 al 40 y presione SIGUIENTE";
              } 
              this.alertType = 'info';
            }      
              
            break;
          case 'third':    
            if(this.thirdNumber.length > 0){
              this.alertText = "Presione SIGUIENTE";
              this.alertType = 'info';
              this.controlsLabelAceptar = 'Siguiente';
            } else{
              if(!this.portrait){
                this.alertText = "Seleccione 5 números y presione INGRESAR";
              }else{
                this.alertText = "Ingrese un Nº del 0 al 40 y presione SIGUIENTE";
              } 
              this.alertType = 'info';
            }      
              
            break;
          case 'fourth':    
            if(this.fourthNumber.length > 0){
              this.alertText = "Presione SIGUIENTE";
              this.alertType = 'info';
              this.controlsLabelAceptar = 'Siguiente';
            } else{
              if(!this.portrait){
                this.alertText = "Seleccione 5 números y presione INGRESAR";
              }else{
                this.alertText = "Ingrese un Nº del 0 al 40 y presione SIGUIENTE";
              } 
              this.alertType = 'info';
            }      
              
            break;
          case 'fifth':    
            if(this.fifthNumber.length > 0){
              this.alertText = "Presione INGRESAR para ingresar la apuesta";
              this.alertType = 'info';
              this.controlsLabelAceptar = 'Ingresar';
            } else{
              if(!this.portrait){
                this.alertText = "Seleccione 5 números y presione INGRESAR";
              }else{
                this.alertText = "Ingrese un Nº del 0 al 40 y presione SIGUIENTE";
              } 
              
              this.alertType = 'info';
            }      
              
            break;
        
          default:
            break;
        }
      }
    },
    resolutionUpdated() {
      var width = Number(window.screen.availWidth);
      var height = Number(window.screen.availHeight);
      //console.log("Resolution Updated " + width + "X" + height);
      if (width < height || width < 1000) {
        this.portrait = true;
        //console.log("Portrait");
      } else {
        this.portrait = false;
        //console.log("Landscape");
      }
    },
    onBet() {
      this.disabledConfirmarBet = true;
      this.acceptDisabled = true;
      this.currentPadTagert = "amountBet";
      this.showBetConfirm = true;
      this.amountBet = "-";
      this.alertType = 'info';
      this.alertText = "Confirme valor de apuesta";
    },
    onReventadoBet() {
      if (this.reventado) {
        this.currentPadTagert = "reventadoAmountBet";
        this.reventadoAmountBet = "-";
      }
    },
    onConfirmarBet() {
      if (this.validateBet()) {
        this.showBetConfirm = false;
        this.currentPadTagert = "currentNumber";
        //this.resetCustomBet = this.previousBet;
        this.disabledConfirmarBet = true;
        this.disabledReventado = false;
        this.validateNumber();
      }
    },
    validateTotal(total, equal) {
      if (equal) {
        if (Number(total) >= this.MAX_BET) {
          this.alertText = "Apuesta máxima: " + this.MAX_BET;
          this.acceptDisabled = true;
        } else {
          if (this.selectedNumbers.length < this.MAX_NUMBERS) {
            if (this.currentNumber != "") {
              if (Number(this.currentNumber) > 100) {
                this.acceptDisabled = false;
              }
            }
          } else {
            this.alertText = "No se pueden jugar mas de 10 números";
          }
          //this.setRaffleAlert();
        }
      } else {
        if (Number(total) > this.MAX_BET) {
          this.alertText = "Apuesta máxima: " + this.MAX_BET;
          this.acceptDisabled = true;
        } else {
          if (this.selectedNumbers.length < this.MAX_NUMBERS) {
            if (this.currentNumber != "") {
              if (Number(this.currentNumber) > 100) {
                this.acceptDisabled = false;
              }
            }
          }
          //this.setRaffleAlert();
        }
      }
      //console.log('Valid Total '+this.acceptDisabled);
    },
    duplicateBet(newNumber) {
      var exits = false;
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        if (
          this.selectedNumbers[i].number == newNumber.number &&
          this.selectedNumbers[i].type == newNumber.type &&
          this.selectedNumbers[i].date == newNumber.date
        ) {
          exits = true;
        }
      }
      return exits;
    },
    onPrint() {   
      this.qrSize = document.getElementById('printTicket').offsetWidth * 0.270;   
      this.printDialog = true;     
    },
    onDialogAccept() {     
      this.onCreateTicket();
    },
    sendToQuickPrinter(params) {
      //console.log("sendToQuickPrinter");
      var text = this.createTicket(params);
      var textEncoded = encodeURI(text);
      window.location.href =
        "intent://" +
        textEncoded +
        "#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;";
    },
    createTicket(params) {
      var ticketText = "";
      var space = " ";

      //Logo
      ticketText += "<center><image>../img/nuevosTiemposLogo.fffadb05.png<br>";

      var tableDelimiter = ";;";
      //Numbers headers
      ticketText +=
        "<center>" +
        this.forceLength("Juego", 10) +
        tableDelimiter +
        this.forceLength("Numero", 10) +
        tableDelimiter +
        this.forceLength("Fecha", 10) +
        tableDelimiter +
        this.forceLength("Monto", 10) +
        tableDelimiter +
        this.forceLength("R", 10);
      ("<br>");

      //Numbers
      for (let i = 0; i < params.ticketItems.length; i++) {
        var number = this.forceLength(params.ticketItems[i].number, 10);
        var bet = this.forceLength(params.ticketItems[i].bet, 10);
        var type = this.forceLength(params.ticketItems[i].type, 10);
        var reventadoBet = " ";
        if (params.ticketItems[i].reventadoBet != "") {
          reventadoBet = params.ticketItems[i].reventadoBet;
        }
        reventadoBet = this.forceLength(reventadoBet, 10);
        var blindRooster = params.ticketItems[i].blindRooster;
        var date = this.forceLength(params.ticketItems[i].date, 10);
        var index = this.getIndex(i);
        var game = index + " " + type.substring(0, 3).toUpperCase();
        game = this.forceLength(game, 10);

        //console.log('Game ticket '+game);

        if (blindRooster != "") {
          game += " " + blindRooster;
        }
        ticketText +=
          "<center>" +
          game +
          tableDelimiter +
          number +
          tableDelimiter +
          date +
          tableDelimiter +
          bet +
          tableDelimiter +
          reventadoBet +
          "<br>";
      }

      //Raffles amount
      ticketText +=
        "<left>Cantidad de sorteos:" + params.ticketItems.length + "<br>";
      //ticketText += "<right>" + params.ticketItems.length + "<br>";

      //Total Bet
      ticketText += "<left>Monto Total:" + params.total + "<br>";
      //ticketText += "<right>" + params.total + "<br>";

      //Messaje
      ticketText += "<center><line><br>";
      ticketText += '<center><big>"MUCHA SUERTE"<br>';
      ticketText += "<center><line><br>";

      //PRINT DATE
      ticketText += "<left>Fecha jugada:" + this.getPrintDate() + "<br>";
      //ticketText += "<right>" + this.getPrintDate() + "<br>";

      //BARDCODE
      var codeNumber = 123465892;
      ticketText += "<center><barcode128>" + codeNumber + "<br><cut>";

      return ticketText;
    },
    forceLength(target, len) {
      var out = String(target);
      while (out.length < len) {
        out += " ";
      }
      return out;
    },
    onDialogCancel() {
      this.printDialog = false;
    },
    getPrintDisabledStatus() {
      if (this.selectedNumbers.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    onUpdateBet(index) {
      if (this.reventado) {
        this.reventadoAmountBet = this.betButtons[index];
        this.validateBet();
        var totalBet =
          Number(this.getTotal) +
          Number(this.amountBet) +
          Number(this.reventadoAmountBet);
        //this.validateTotal(totalBet, false);
      } else {
        this.amountBet = this.betButtons[index];
        this.validateBet();
        var totalBet = Number(this.getTotal) + Number(this.amountBet);
        //this.validateTotal(totalBet, false);
      }
    },
    onUpdateCustomBet() {
      this.previousBet = this.amountBet;

      if (this.reventado) {
        this.currentPadTagert = "reventadoAmountBet";
        this.reventadoAmountBet = "-";
      } else {
        this.currentPadTagert = "amountBet";
        this.amountBet = "-";
        this.showBetConfirm = true;
        this.acceptDisabled = true;
        this.disabledConfirmarBet = true;
        this.disabledReventado = true;
      }
      this.alertText = "Confirme valor de apuesta";
    },
    onUpdateRaffleType(type) {
      this.currentRaffleType = type;
      this.validateNumber();
    },
    onUpdateRaffleDay(index) {
     
     
      this.currentRaffleDay = this.days[index];
      //console.log('onUpdateRaffleTime '+this.currentRaffleDay)
    },
    onUpdateRaffleTime(index) {
      this.currentRaffleTime = this.times[index];
      
    },
    onUpdateRevetando(reventado) {
      this.reventado = reventado;

      if (!this.reventado) {
        this.reventadoAmountBet = 0;
        this.currentPadTagert = "currentNumber";
        this.alertType = "info";
        this.setRaffleAlert();
      } else {
        this.reventadoAmountBet = "-";
        this.currentPadTagert = "reventadoAmountBet";
        this.alertType = "info";
        this.alertText = "Confirme valor de apuesta para reventado";
        this.acceptDisabled = true;
      }
    },
    onKeyboardUpdate(index) {


      if(!this.portrait){

        if (this.valueButtons[index] == "<") {
          if(this.fifthNumber.length > 0){
            this.fifthNumber =  '';  
            this.currentRaffle.numbers[4] = -1;          
          }else  if(this.fourthNumber.length > 0){
            this.fourthNumber =  '';     
            this.currentRaffle.numbers[3] = -1;        
          }else  if(this.thirdNumber.length > 0){
            this.thirdNumber =  '';
            this.currentRaffle.numbers[2] = -1; 
          } else if(this.secondNumber.length > 0){
            this.secondNumber =  '';
            this.currentRaffle.numbers[1] = -1; 
          } else if(this.firstNumber.length > 0){
            this.firstNumber =  '';
            this.currentRaffle.numbers[0] = -1; 
          }
        }else if(this.valueButtons[index] == "BORRAR"){
            this.firstNumber  =  '';
            this.secondNumber =  '';
            this.thirdNumber  =  '';
            this.fourthNumber =  '';
            this.fifthNumber  =  '';
            this.currentRaffle.numbers = [-1,-1,-1,-1,-1]; 
        }else{
          if(this.firstNumber.length == 0){
            this.firstNumber =  this.valueButtons[index];           
          }else  if(this.secondNumber.length == 0){
            this.secondNumber =  this.valueButtons[index];
          }else  if(this.thirdNumber.length == 0){
            this.thirdNumber =  this.valueButtons[index];
          } else if(this.fourthNumber.length == 0){
            this.fourthNumber =  this.valueButtons[index];
          } else if(this.fifthNumber.length == 0){
            this.fifthNumber =  this.valueButtons[index];
          }
        }

        if(this.fifthNumber.length > 0){
          this.acceptDisabled = false;
        }

        this.validateCurrentStep();

      }else{      
     
          if (this.valueButtons[index] == "BORRAR"){
             this.firstNumber = "";
             this.secondNumber = "";
             this.thirdNumber = "";
             this.fourthNumber = "";
             this.fifthNumber = "";
             this.portraitFirstImage = true;
             this.portraitSecondImage = true;
             this.portraitThirdImage = true;
             this.portraitFourthImage = true;
             this.portraitFifthImage = true;
             this.currentRaffle.numbers = [-1,-1,-1,-1,-1];
             this.currentNumber = 'first';
          }

           if (this.valueButtons[index] == "<") {
              if(this.secondNumber.length === 0){
                      this.portraitSecondImage = true;
               }
              if(this.thirdNumber.length === 0){
                  this.portraitThirdImage = true;
                }
              if(this.fourthNumber.length === 0){
                this.portraitFourthImage = true;
                }
              if(this.fifthNumber.length === 0){
                this.portraitFifthImage = true;
                }
           }
          
          switch (this.currentNumber) {
            case "first":
               if (this.valueButtons[index] == "<") {

               

                 this.firstNumber = String(this.firstNumber).slice(0, -1);
                 this.currentRaffle.numbers[0] = -1;
               } else if (this.valueButtons[index] != "BORRAR") {
                   if (this.firstNumber.length < 2) {
                    this.firstNumber += this.valueButtons[index];
                     this.portraitFirstImage = false;
                  }
               }
           
                break;
              case "second":
                 if (this.valueButtons[index] == "<") {

                   

                   if(this.secondNumber.length === 0){
                    
                     this.firstNumber = String(this.firstNumber).slice(0, -1);
                     this.currentRaffle.numbers[0] = -1;
                     this.currentNumber = 'first';
                     //this.portraitSecondImage = true;
                   }else{
                     this.secondNumber = String(this.secondNumber).slice(0, -1);
                     this.currentRaffle.numbers[1] = -1;
                   }
                    
                  } else if (this.valueButtons[index] != "BORRAR") {
                      if (this.secondNumber.length < 2) {
                        this.secondNumber += this.valueButtons[index];
                      }
                  }
               
                break;
              case "third":
                 if (this.valueButtons[index] == "<") {

                 
                   if(this.thirdNumber.length === 0){
                    
                     this.secondNumber = String(this.secondNumber).slice(0, -1);
                     this.currentRaffle.numbers[1] = -1;
                     this.currentNumber = 'second';
                     //this.portraitThirdImage = true;

                   }else{
                      this.thirdNumber = String(this.thirdNumber).slice(0, -1);
                      this.currentRaffle.numbers[2] = -1;
                   }
                   
                  } else if (this.valueButtons[index] != "BORRAR") {
                      if (this.thirdNumber.length < 2) {
                        this.thirdNumber += this.valueButtons[index];
                      }
                  }
                
                break;
              case "fourth":
                 if (this.valueButtons[index] == "<") {
                  
                   if(this.fourthNumber.length === 0){
                    
                     this.thirdNumber = String(this.thirdNumber).slice(0, -1);
                     this.currentRaffle.numbers[2] = -1;
                     this.currentNumber = 'third';
                     //this.portraitThirdImage = true;

                   }else{
                     this.fourthNumber = String(this.fourthNumber).slice(0, -1);
                     this.currentRaffle.numbers[3] = -1;
                   }
                    
                  } else if (this.valueButtons[index] != "BORRAR") {
                     if (this.fourthNumber.length < 2) {
                        this.fourthNumber += this.valueButtons[index];
                      }
                  }
               
                break;
              case "fifth":
                 if (this.valueButtons[index] == "<") {
                   if(this.fifthNumber.length === 0){
                    
                     this.fourthNumber = String(this.fourthNumber).slice(0, -1);
                     this.currentRaffle.numbers[3] = -1;
                     this.currentNumber = 'fourth';
                     //this.portraitFifthImage = true;
                     
                   }
                    this.fifthNumber = String(this.fifthNumber).slice(0, -1);
                    this.currentRaffle.numbers[4] = -1;
                  } else if (this.valueButtons[index] != "BORRAR") {
                    if (this.fifthNumber.length < 2) {
                      this.fifthNumber += this.valueButtons[index];
                    }
                  }
               
                break;

            default:
              break;
          }
          this.checkStep();
       }
    },
    onClear() {     
      this.selectedNumbers = [];    
      this.defaultDay = !this.defaultDay;
      this.clearCurrentRaffle();

      this.acceptDisabled = false;

     
     
    },
    onDelete(index) {
      this.selectedNumbers.splice(index, 1); 
      if(this.acceptDisabled && this.selectedNumbers.length < 8){
        this.acceptDisabled = false;
      }   
      this.checkStep();
    },
    insertRaffle() {
     
      
      if(!this.currentRaffle.numbers.includes(-1)){
        //console.log('Complete!')
        //console.log('onIngresar '+this.currentRaffleDay);
        this.currentRaffle.bet = this.amountBet;
        this.currentRaffle.weekDay = this.currentRaffleDay ;     
        for (let i = 0; i < this.weekDays.length; i++) {          
          if(String(this.weekDays[i].weekDay).toLowerCase() === String(this.currentRaffle.weekDay).toLowerCase()){
            
            if(this.currentRaffle.date.length === 0){
              this.currentRaffle.date = this.weekDays[i].date;
            }
            
          }       
          
        }
       
             
        if (this.blindRooster) {
          this.currentRaffle.blindRooster = "GT";
        }else{
           this.currentRaffle.blindRooster = "";
        }
        if (this.revenge) {
          this.currentRaffle.revenge = "R";
        }else{
           this.currentRaffle.revenge = "";
        }
       
        
        if(this.selectedNumbers.length < 8){
          this.selectedNumbers.push({...this.currentRaffle});
         
        }        
        
       
        //this.clearCurrentState(this.RAFFLE_NUMBER_STATE);
        
        ///this.currentState = this.RAFFLE_NUMBER_STATE;
       
        this.clearCurrentRaffle(); 

        this.portraitFirstImage=true;
        this.portraitSecondImage=true;
        this.portraitThirdImage=true;
        this.portraitFourthImage=true;
        this.portraitFifthImage=true;

        //this.currentRaffleDay = this.days[0];
        //this.amountBet = 600;

        if(this.selectedNumbers.length == 8){
          this.acceptDisabled = true;
          this.alertText = "No se pueden jugar mas de 8 sorteos";
          this.alertType = 'info';
        }
      }

      
      
     
     
    },
    onAceptar() {

      //console.log('onAceptar '+this.currentState)
     if(this.portrait){
           
         
          switch (this.currentNumber) {
            case "first":
                if (this.firstNumber.length > 0 && this.validateCurrentStep()){
                  this.portraitSecondImage = false;
                }
                 
                break;
              case "second":
                 if (this.secondNumber.length > 0 && this.validateCurrentStep()){
                    this.portraitThirdImage = false;
                 }
                break;
              case "third":
                 if (this.thirdNumber.length > 0 && this.validateCurrentStep()){
                    this.portraitFourthImage = false;
                 }
                break;
              case "fourth":
                 if (this.fourthNumber.length > 0 && this.validateCurrentStep()){
                    this.portraitFifthImage = false;
                 }
                break;
              case "fifth":
                //console.log('fifth');
                break;

            default:
              break;
          }

        }
        if(this.validateCurrentStep()){
          //this.currentState = this.INSERT_RAFFLE_STATE;
           this.insertRaffle();
        }

       
     


    },
 
    validateCurrentStep(){
       let repeatNumber = false;
       let exceededNumber = false;
       let lastNumber = false;
        //console.log('validateCurrentStep '+this.currentRaffle.numbers[0])
        if(this.currentStep < this.totalSteps){
          if(this.firstNumber.length > 0){

            if(this.currentRaffle.numbers[0] === -1){
              if(this.currentRaffle.numbers.indexOf(this.firstNumber) === -1){
                
                if(this.checkRepeatNumber(this.firstNumber,'first')){
                  repeatNumber = true;
                }else{
                  if(Number(this.firstNumber) > 40){
                    exceededNumber = true;
                  }else{  
                    this.currentRaffle.numbers[0] = this.firstNumber;
                    this.currentNumber = 'second';
                  }
                 
                }
                
              }else{              
                repeatNumber = true;
              }
              
            }          
          
            this.keyboardNumber = '';
          }else{
            this.currentRaffle.numbers[0] = -1;
          }
          if(this.secondNumber.length > 0){
            
            if(this.currentRaffle.numbers[1] === -1){
              if(this.currentRaffle.numbers.indexOf(this.secondNumber) === -1){
               
                if(this.checkRepeatNumber(this.secondNumber,'second')){
                  repeatNumber = true;
                }else{
                   if(Number(this.secondNumber) > 40){
                    exceededNumber = true;
                  }else{
                    this.currentRaffle.numbers[1] = this.secondNumber;
                    this.currentNumber = 'third';
                  }
                  
                }
              }else{
              
                repeatNumber = true;
              }
            }
            
            this.keyboardNumber = '';
          }else{
            this.currentRaffle.numbers[1] = -1;
          }
          if(this.thirdNumber.length > 0){
            
            if(this.currentRaffle.numbers[2] === -1){
              if(this.currentRaffle.numbers.indexOf(this.thirdNumber) === -1){
                
                if(this.checkRepeatNumber(this.thirdNumber,'third')){
                  repeatNumber = true;
                }else{
                   if(Number(this.thirdNumber) > 40){
                    exceededNumber = true;
                  }else{
                    this.currentRaffle.numbers[2] = this.thirdNumber;
                    this.currentNumber = 'fourth';
                  }
                 
                }
              }else{
                
                repeatNumber = true;
              }
            }
            
            this.keyboardNumber = '';
          }else{
            this.currentRaffle.numbers[2] = -1;
          }
          if(this.fourthNumber.length > 0){
          
            if(this.currentRaffle.numbers[3] === -1){
              if(this.currentRaffle.numbers.indexOf(this.fourthNumber) === -1){
                
                if(this.checkRepeatNumber(this.fourthNumber,'fourth')){
                  repeatNumber = true;
                }else{
                   if(Number(this.fourthNumber) > 40){
                    exceededNumber = true;
                  }else{
                    this.currentRaffle.numbers[3] = this.fourthNumber;
                    this.currentNumber = 'fifth';
                  }
                  
                }
              }else{
              
                repeatNumber = true;
              }
            }
            
            this.keyboardNumber = '';
          }else{
            this.currentRaffle.numbers[3] = -1;
          }
          // console.log('this.fifthNumber.length '+this.fifthNumber.length)
          if(this.fifthNumber.length > 0){
          
            if(this.currentRaffle.numbers[4] === -1){
              if(this.currentRaffle.numbers.indexOf(this.fifthNumber) === -1){
                
                if(this.checkRepeatNumber(this.fifthNumber,'fifth')){
                  repeatNumber = true;                 
                }else{
                   if(Number(this.fifthNumber) > 40){
                    exceededNumber = true;
                  }else{
                    this.currentRaffle.numbers[4] = this.fifthNumber;
                    this.currentNumber = 'fifth';
                    this.controlsLabelAceptar = 'Ingresar';
                    this.alertText = "Pulse ingresar para confirmar la apuesta";
                    this.alertType = 'info';
                   
                    lastNumber = true;
                  }
                  
                }
              }else{                
                repeatNumber = true;
              }
            }else{
               
              lastNumber = true;
            }
            
            this.keyboardNumber = '';
           
           
          }else{
            this.currentRaffle.numbers[4] = -1;
          }

        }
        let valid = true;
        //console.log('Current selected numbers '+this.currentRaffle.numbers+'  '+lastNumber);
        if(this.checkRepeatRaffle()){
          this.alertText = 'No se pueden repetir los sorteos';
          this.alertType = 'error';
          valid = false;
        }else if(repeatNumber){
          this.alertText = 'No se pueden repetir los números';
          this.alertType = 'error';
          valid = false;
        }else if(exceededNumber){
          this.alertText = 'Los números no pueden ser mayores a 40';
          this.alertType = 'error';
          valid = false;
        }else if(!lastNumber){
          
          this.checkStep();
        }

        return valid;

    },
    checkRepeatNumber(number, type){
      let repeat = false;
      switch (type) {
        case 'first':
          if(number === this.secondNumber 
          || number === this.thirdNumber
          || number === this.fourthNumber
          || number === this.fifthNumber){
            repeat = true;
          }
          break;
        case 'second':
          if(number === this.firstNumber 
          || number === this.thirdNumber
          || number === this.fourthNumber
          || number === this.fifthNumber){
            repeat = true;
          }
          break;
        case 'third':
          if(number === this.secondNumber 
          || number === this.firstNumber
          || number === this.fourthNumber
          || number === this.fifthNumber){
            repeat = true;
          }
          break;
        case 'fourth':
          if(number === this.secondNumber 
          || number === this.thirdNumber
          || number === this.firstNumber
          || number === this.fifthNumber){
            repeat = true;
          }
          break;
        case 'fifth':
          if(number === this.secondNumber 
          || number === this.thirdNumber
          || number === this.firstNumber
          || number === this.firstNumber){
            repeat = true;
          }
          break;
      
        default:
          break;
      }
    },
   
    checkRepeatRaffle(number){
      let repeatRaffle = false;
      this.selectedNumbers.forEach(raffle => {
       /*  console.log('checkRepeatRaffle '+number)
        console.log('checkRepeatRaffle '+this.currentRaffleDay)
        console.log('---')
        console.log('checkRepeatRaffle '+raffle.date)
        console.log('checkRepeatRaffle '+raffle.numbers) */
        if(String(raffle.weekDay).toLowerCase() === String(this.currentRaffleDay).toLowerCase() ){

          if(JSON.stringify(this.currentRaffle.numbers) === JSON.stringify(raffle.numbers)){           
            repeatRaffle = true;
            return repeatRaffle;
          }
          /* if(raffle.numbers.includes(number)){
            repeatRaffle = true;
            return repeatRaffle;
          } */
        }
      });
      return repeatRaffle;
    },
    clearCurrentRaffle(){
      this.firstNumber = '';
      this.secondNumber = '';
      this.thirdNumber = '';
      this.fourthNumber = '';
      this.fifthNumber = '';
      this.currentRaffle.numbers = [-1,-1,-1,-1,-1];
      this.currentRaffle.date = '';
      this.currentNumber = 'first';
      this.currentStep = 1;
      this.controlsLabelAceptar = 'Ingresar';
      this.alertText = 'Ingrese un Nº del 0 al 40 y presione SIGUIENTE';
      this.blindCounter = 0;
      this.blindRooster = false;
      if(!this.portrait){
        this.alertText = 'Seleccione 5 números y presione INGRESAR';
        this.acceptDisabled = true;
      }
      this.alertType = 'info';
    },
    resetValues(resetDefault = false) {
      this.reventadoAmountBet = 0;
      this.currentNumber = "";
      this.reventado = false;
      this.blindRooster = false;
      this.reset = !this.reset;
      this.resetDefault = resetDefault;
      this.currentPadTagert = "currentNumber";
      this.acceptDisabled = true;
      

      if (this.resetDefault) {
        var now = new Date();
        var hours = now.getHours();

       
        this.currentRaffleDay = this.days[0];
        this.amountBet = this.betButtons[0];
      }
      this.validateNumber();
      this.validateTotal(Number(this.getTotal), true);
    },
    onRevenge(){
       this.revenge = !this.revenge;
       if(this.revenge){
         this.amountBet += this.revengeAmountBet;
       }else{
         this.amountBet -= this.revengeAmountBet;
       }
    },
    onBlindRooster() {
      this.blindRooster = true;
      let existNumbers = []; 
      let raffleNumbers = this.getSelectedNumbersByRaffle();
      for (let e = 0; e < raffleNumbers.length; e++) {
        existNumbers.push(raffleNumbers[e]);
        
      }
      
      if(this.portrait){
        this.portraitFirstImage = false;
        this.portraitSecondImage = false;
        this.portraitThirdImage = false;
        this.portraitFourthImage = false;
        this.portraitFifthImage = false;
      }
      
      
      this.firstNumber = String(this.getRandomIntExcludingExistingNumbers(0,40,existNumbers));
      this.currentRaffle.numbers[0] = this.firstNumber;
      existNumbers.push(this.firstNumber);
      this.secondNumber = String(this.getRandomIntExcludingExistingNumbers(0,40,existNumbers));
      this.currentRaffle.numbers[1] = this.secondNumber;
       existNumbers.push(this.secondNumber);
      this.thirdNumber = String(this.getRandomIntExcludingExistingNumbers(0,40,existNumbers));
      this.currentRaffle.numbers[2] = this.thirdNumber;
       existNumbers.push(this.thirdNumber);
      this.fourthNumber = String(this.getRandomIntExcludingExistingNumbers(0,40,existNumbers));
      this.currentRaffle.numbers[3] = this.fourthNumber;
      existNumbers.push(this.fourthNumber);
      this.fifthNumber = String(this.getRandomIntExcludingExistingNumbers(0,40,existNumbers));
      this.currentRaffle.numbers[4] = this.fifthNumber;
      existNumbers.push(this.fifthNumber);

      this.currentNumber = 'fifth';

       //if(!this.portrait){
        this.alertText = 'Presione INGRESAR';
        this.alertType = 'info';
        this.acceptDisabled = false;
      //}
      
    },   
  
    getRandomIntExcludingExistingNumbers(min, max, excludeArrayNumbers) {
      let randomNumber;

      if(!Array.isArray(excludeArrayNumbers)) {
        randomNumber = this.getRandomInRange(min, max);
        return randomNumber;
      }

      do {
        randomNumber = this.getRandomInRange(min, max);
      } while ((excludeArrayNumbers || []).includes(randomNumber));

      return randomNumber;
    },
    getSelectedNumbersByRaffle(){
      let snumbers = [];
      this.selectedNumbers.forEach(raffle => {        
         if(raffle.date === this.currentRaffleDay){
           for (let i = 0; i < raffle.numbers.length; i++) {              
              snumbers.push(raffle.numbers[i])
             
           }           
         }
      });     
      return snumbers;
    },
    setRaffleAlert() {
     
      //console.log('this.currentNumber.length '+this.currentNumber.length);   
      this.alertType = 'info';  
      if (String(this.currentNumber).length == 0) {
        
        this.alertText = "Ingrese 3 números del 0 al 9";
      }
      if (String(this.currentNumber).length == 1) {
        this.alertText = "Ingrese 2 números del 0 al 9";
      }
      if (String(this.currentNumber).length == 2) {
        this.alertText = "Ingrese 1 número del 0 al 9";
      }      
      if (String(this.currentNumber).length == 3) {
        this.alertText = "Presione INGRESAR para ingresar la apuesta";
      }
      
    },
    validateNumber() {
      var valid = true;

      switch (String(this.currentRaffleType).toLowerCase()) {
        case "orden":
          if (Number(this.currentNumber) < 100) {
            /* this.alertType = "error";
            this.alertText = "El número debe tener minimo 3 cifras"; */
            valid = false;
          }
          break;
        case "ultimos":
          /* if (String(this.currentNumber.length > 1)) {
            var digits = String(this.currentNumber).split("");

            var equals_digit = [digits[0]];
            for (let e = 0; e < digits.length; e++) {
              if (equals_digit.indexOf(digits[e]) == -1) {
                equals_digit.push(digits[e]);
              }
            }
            if (digits.length > 1 && equals_digit.length == 1) {
              this.alertType = "error";
              this.alertText = "Los dígitos del número no pueden ser iguales";
              valid = false;
            }
          } */
          if (Number(this.currentNumber) < 100) {
            /* this.alertType = "error";
            this.alertText = "El número debe tener minimo 3 cifras"; */
            valid = false;
          }

          break;
        case "desorden":
          if (Number(this.currentNumber) < 100) {
            /* this.alertType = "error";
            this.alertText = "El número debe tener minimo 3 cifras"; */
            valid = false;
          }

          break;
        case "ord/des":
          if (Number(this.currentNumber) < 100) {
            /* this.alertType = "error";
            this.alertText = "El número debe tener minimo 3 cifras"; */
            valid = false;
          }
        default:
          break;
      }
      if (this.currentNumber == "") {
        this.alertType = "info";
        this.setRaffleAlert();
        valid = false;
      }
     

      if (valid) {
        this.alertType = "info";
        this.setRaffleAlert();
        if (this.selectedNumbers.length < this.MAX_NUMBERS) {
          this.acceptDisabled = false;
        } else {
          this.alertText = "No se pueden jugar mas de 10 números";
        }
      } else {
        this.setRaffleAlert();
        this.acceptDisabled = true;
      }
      //console.log('Valid '+this.acceptDisabled);
    },
    validateBet() {
      var valid = true;
      if (!this.reventado) {
        if (this.amountBet == "-") {
          this.alertType = "info";
          this.alertText = "Confirme valor de apuesta";
          valid = false;
        } else if (this.amountBet == 0) {
          this.alertType = "error";
          this.alertText = "El valor de apuesta no puede ser 0";
          valid = false;
        } else if (Number(this.amountBet) < this.MIN_BET) {
          this.alertType = "error";
          this.alertText = "El valor de apuesta minimo es de " + this.MIN_BET;
          valid = false;
        } else if (
          Number(this.getTotal) + Number(this.amountBet) >
          this.MAX_BET
        ) {
          this.alertText = "EL maximo de apuesta es de " + this.MAX_BET;

          valid = false;
        } else {
          this.alertType = "info";
          this.setRaffleAlert();
        }
      } else {
        if (this.reventadoAmountBet == "-") {
          this.alertType = "info";
          this.alertText = "Confirme valor de apuesta para reventado";
          valid = false;
        } else if (this.reventadoAmountBet == 0) {
          this.alertType = "error";
          this.alertText = "El valor de apuesta no puede ser 0";
          valid = false;
        } else if (
          Number(this.getTotal) +
            Number(this.reventadoAmountBet) +
            Number(this.amountBet) >
          this.MAX_BET
        ) {
          //console.log("Apuesta MAXIMA");
          this.alertText = "EL maximo de apuesta es de " + this.MAX_BET;
          valid = false;
        } else {
          this.alertType = "info";
          this.setRaffleAlert();
        }
      }

      if (!valid) {
        this.acceptDisabled = true;
        this.disabledConfirmarBet = true;
      } else {
        //this.acceptDisabled = false;
        this.disabledConfirmarBet = false;
      }
      return valid;
    },
    setDates() {
      var wDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

     /*  this.weekDays = [
        {
          weekDay: "HOY",
          date: dd + "/" + mm,
        },
      ]; */

      for (let i = 0; i < 8; i++) {
        //new Date(1995,11,17);
        var weekDay = new Date(yyyy, mm, Number(dd) + i );
        var date =
          String(weekDay.getDate()).padStart(2, "0") +
          "/" +
          String(weekDay.getMonth() + 1).padStart(2, "0"); //January is 0!

        var day = {
          weekDay: wDays[weekDay.getDay()],
          date: date,
        };

        this.weekDays.push(day);
      }
    },
    getRandomInRange(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getPrintDate() {
      var weekDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      var months = [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ];
      var today = new Date();
      var dd = today.getDate();
      var mm = months[today.getMonth()+1];
      var yyyy = today.getFullYear();
      var weeDay = weekDays[today.getDay()];
      var hours = today.getHours();
      var minutes = this.getZeroPad(today.getMinutes());

      var printDate =
        weeDay +
        " " +
        dd +
        " " +
        mm +
        " " +
        yyyy +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        this.getHourTime(hours);

      return printDate;
    },
    getPrintTicketDate(){
     
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      var hours = today.getHours();
      var minutes = this.getZeroPad(today.getMinutes());

      var printDate =
        dd +
        "/" +
        mm +
        "/" +
        yyyy +
        "/" +
        hours +
        ":" +
        minutes;
        

      return printDate;
    },
    getHourTime(h) {
      return h >= 12 ? "PM" : "AM";
    },
    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? "" : "0") + n;
    },
    getIndex(index) {
      var alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return alphabet[index];
    },
    checkDefault() {

      let wDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      
     
      let weekDay = new Date(yyyy, mm, Number(dd) );

      let todayDayWeek  = wDays[weekDay.getDay()];
      
      if(todayDayWeek === "DOM" || todayDayWeek === "LUN"
      || todayDayWeek === "MAR" || todayDayWeek === "MIE" ){
        this.currentRaffleDay = this.days[0]; 
      }else if(todayDayWeek === "JUE" || todayDayWeek === "VIE"  || todayDayWeek === "SAB"){
        this.currentRaffleDay = this.days[1];
      }

      //console.log('WeekDay HOY '+wDays[weekDay.getDay()])
    
    },
  },
};
</script>