import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'


Vue.use(Vuetify,{
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
     
   
});

export default new Vuetify({
});
