<template>
  <div class="login-container">
    <v-card class="login-card" elevation="10">
      <v-card-title class="login-title">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="../assets/logos/end2end_logo.png"
          transition="scale-transition"
          width="210"
        />
        <!-- {{ lblTitle }}     -->
      </v-card-title>
      <v-card-text class="login-card-body">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form ref="form" @submit.prevent="submit" :error-messages="invalid">
            <validation-provider
              v-slot="{ errors }"
              :name="lblName"
              rules="required"
            >
              <v-text-field
                v-model="name"
                :error-messages="errors"
                :label="lblName"
                ref="name"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :name="lblPassword"
              rules="required"
            >
              <v-text-field
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpass ? 'text' : 'password'"
                :label="lblPassword"
                :error-messages="errors"
                required
                ref="pass"
                v-model="password"
                @click:append="showpass = !showpass"
              ></v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="card-buttons-container">
        <p class="invalid-credentials">{{ lblInvalidCredentials }}</p>

        <v-btn @click.prevent="submit" elevation="3" x-large class="btn-login">
          {{ lblLogin }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import {
  configure,
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

/* 
configure({
  classes: {
    pending: 'vee-valid',
    
  }
}) */

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacio",
});

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      lblTitle: "Inicie sesión",
      lblName: "Nombre de usuario",
      lblPassword: "Contraseña",
      lblLogin: "Ingresar",
      lblInvalidCredentials: "",
      name: "",
      password: "",
      showpass: false,
    };
  },
  mounted() {
    window.addEventListener("keypress", this.keyPress);
    //this.$refs.name.focus();
  },
  destroyed() {
    window.removeEventListener("keypress", this.keyPress);
  },
  methods: {
    keyPress(e) {
      switch (e.keyCode) {
        case 13://ENTER
          this.onEnter();
          break;

        default:
          break;
      }
    },
    onEnter() {
      if(this.password == ''){
        this.$refs.pass.focus();
      }
      this.submit();
    },
    submit() {
      //console.log("submit");
      this.$refs.observer.validate();
      if (this.name != "" && this.password != "") {
        var params = {
          name: this.name,
          password: this.password,
        };
        this.$store.dispatch("login", params).then(() => {
          if (this.$store.getters.engineResult.success == true) {           
            this.$router.push("selector");
          } else {
            console.log("Error " + this.$store.getters.engineResult.error);
            var loginError = this.$store.getters.engineResult.error;
            if (loginError == "InvalidCredentials") {
              this.lblInvalidCredentials = "Credenciales invalidas";
            }
          }
        });
      }
    },
  },
};
</script>
