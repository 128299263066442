<template>
  <div class="selector-container">
    <div
      @click="onGameSelected(index)"
      :class="getClass(game, index)"
      v-for="(game, index) of games"
      :key="index"
    >
      <v-img        
        alt="Logo"
        :class="game.imageClass"
        :src="game.img"
        transition="scale-transition"
      />
     
      
      <div v-if="game.lock && game.img != ''" class="game-locked">
       <p class="lock-text">PRÓXIMAMENTE</p>
      </div>
     
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lblTitle: "SELECCIONA UN JUEGO",
      games: [],
    };
  },
  created() {
    
    this.games = [
      {
        game: "nuevosTiempos",      
        /* img: "assets/img/nuevosTiemposLogo.2dd7955d.png", */
        img: "/static/dist/img/nuevosTiemposLogo.2dd7955d.png",
        imageClass:"selector-game-logo",
        lock: false,
      },
      { game: "tresMonazos",    
       img: "/static/dist/img/tresMonazosLogo.18016002.png",
        lock: false, imageClass:"selector-game-logo-small" },
      { game: "lotto",
       img: "/static/dist/img/lottoLogo.6e8555b5.png",       
       lock: false, imageClass:"selector-game-logo" },
      { game: "other", img: "", lock: true , imageClass:"selector-game-logo"},
    ];
  },
  mounted(){
   
    
    this.$store.dispatch("setTitle", 'Loteria');
  },
  methods: {
   
   
    getClass(game, index) {
      var className = "";
      if (game.lock) {
        if (index % 3 == 0) {
          className = "game-image-lock-2";
        } else {
          className = "game-image-lock-1";
          /*  if (index % 4 == 0) {
            className = "game-image-lock-2";
          }
          if (index % 6 == 0) {
            className = "game-image-lock-2";
          } */
        }
      } else {
        return (className = "game-image-unlock");
      }
      return className;
    },
    onGameSelected(index) {
      //console.log("Game Selected " + this.games[index]);
      if (!this.games[index].lock) {
        this.$store.dispatch("setSelectedGame", this.games[index])
        .then(res =>{
          this.$router.push(this.games[index].game);
        });      
        
      }
    },
  },
};
</script>
