<template>
  <div class="display-panel-portrait">
   

    <div class="display-element-portrait">
      <div class="number-display-portrait">
        <span v-if="currentNumber">{{ currentNumber }}</span>
        <v-img
        v-else     
        class="display-element-image"          
        src= "/static/dist/img/nuevosTiemposLogo.2dd7955d.png"
        ></v-img>
        </div>
     
    </div>
    <div class="display-element-portrait">
      <p class="number-bet-label">
        {{ lblDisplayBet | addCurrency(currency) }}
      </p>
      <p  class="number-bet">{{ amountBet }}</p>
     <!--  <v-fade-transition>
        <v-btn
          id="btnConfirmarBet"
          v-show="showBetConfirm"
          @click="onConfirmarBetTap"
          class="confirmar-button"
          >{{ lblConfirmarBet }}</v-btn
        >
      </v-fade-transition> -->
    </div>
    <div class="display-element-portrait">
      <p class="number-bet-label lbl-reventado">{{ lblReventadoBet }}</p>
      <p @click="onReventadoBetTap" class="number-bet">
        {{ reventadoAmountBet }}
      </p>
       <v-switch
          class="switch-reventados"
          inset
          v-model="reventado"
         
          :disabled="disabledReventado"
          @change="onReventadoTap"
          color=#0077AD
        >
        <template v-slot:label><span class="switch-reventados-label">{{lblReventados}}</span></template>
        </v-switch>
       <!-- <p class="switch-reventados-label" style="margin-top:-20px">{{lblReventados}}</p> -->
    </div> 
  </div>
</template>

<script>
export default {
  name: "DisplayPanel",
  data() {
    return {
      lblPrimero: "Primero",
      lblTerminacion: "Terminación",
      lblExacto: "Exacto",
      lblReversible: "Reversible",
      lblRafflesTitle: "Sorteos",
      lblRaffles: "Sorteo",
      lblDays: "Dias de la semana",
      lblReventados: "REVENTADOS",
      lblDisplayBet: "Valor de apuesta en ",
      lblReventadoBet: "Apuesta reventados",
      lblNotifications: "Notificaciones",
      lblControls: "Tipo de apuesta",
      lblConfirmarBet: "Confirmar",
      reventado: false,   
      disabledReventado:false  
    };
  },
  props: [
    "currentNumber",
    "amountBet",
    "reventadoAmountBet",    
    "currency",
    "reset",
    "showBetConfirm",
    "disabledConfirmarBet",   
    "currentRaffleType",
  ],
  watch: {
    reset(val) {
      this.resetValues();
    },   
    currentRaffleType(){

      switch (String(this.currentRaffleType).toLowerCase()) {
        case 'primero':
        case 'terminación':
        case 'reversible':
          this.reventado = false;
          this.disabledReventado = true;
          break;
        case 'exacto':
          this.disabledReventado = false;
          break;  
      
        default:
          break;
      }

    }
  },
 
  filters: {
    addCurrency(val, currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " " + currency;
      }
    },
  },
  methods: {
    onBetTap() {
      this.$emit("on-bet");
    },
    onReventadoBetTap() {
      this.$emit("on-reventado-bet");     
    },
    onConfirmarBetTap() {
      this.$emit("on-confirmar-bet");
    },
    getDisabledStatus() {
      if (
        String(this.currentRaffleType).toLowerCase() == "exacto" &&
        this.currentNumber != "" &&
        !this.disabledReventado
      ) {
        return false;
      } else {
        return true;
      }
    },
    resetValues() {
      this.reventado = false;
    },     
    onReventadoTap() {
      this.$emit('on-reventado-change',this.reventado);  
    },
    setReventado(state) {
      this.reventado = state;
      this.toggleActiveReventado(this.reventado);
      this.$emit("update-reventado", this.reventado);
    },
   
    toggleActiveReventado(active) {
      var button = document.getElementById("btnReventado");
      if (active) {
        button.classList.add("reventado-button-active");
      } else {
        button.classList.remove("reventado-button-active");
      }
    },
  },
};
</script>

