<template>
  <div class="raffle-date-container">
      <v-btn :disabled="disabled" ref="btnDate" class="raffle-option-button" @click="onDateChange">{{getDate}}</v-btn>
      <v-btn :disabled="getDisableMatutino()" ref="btnTime1" class="time-option-button"  @click="onTimeChange('M')">M</v-btn>
      <v-btn :disabled="disabled" ref="btnTime2" class="time-option-button" @click="onTimeChange('N')">N</v-btn>
  </div>
</template>

<script>

export default {
    data(){
        return{
            on:false,
            time:'M',
            disabledMatutino:false
        }
    },
    props:[
        "date",
        "index",
        "matutinoTime",
        "nocturnoLimit",
        "disabled",
        "default"
        
    ]
    ,
    watch:{
        disabled(){
            setTimeout(() => {
                this.checkLimitTime();      
            }, 50);
          
        },
        default(){
            //console.log("Rafle date default")
            if(String(this.date.weekday).toLowerCase() === 'hoy'){
                setTimeout(() => {
                    this.forceOn();   
                }, 50);
              
            }else{
                 setTimeout(() => {
                    this.forceOff();   
                }, 50);
                
            }
        }
    },
    computed:{
        getDate(){
            if(String(this.date.weekday).toLowerCase() === 'hoy'){
                return this.date.weekday;
            }else{
                return this.date.weekday+' '+this.date.date;
            }
        }
    },
    mounted(){
       // this.$refs.btnTime1.$el.classList.add("time-option-active");
       this.checkLimitTime();
      
    },
    methods:{
            getDisableMatutino(){
                if(this.disabled){
                    return true;
                }else{
                    return this.disabledMatutino;
                }
            },
          checkLimitTime(){
            let currentDate = new Date();      
            let hours = currentDate.getHours();
            let minutes = currentDate.getMinutes();
            let seconds = currentDate.getSeconds();
            let nowTime = hours+':'+minutes+':'+seconds;
            let limitTime = this.matutinoTime+':00';    

            let nowDate = new Date(currentDate.getTime());
            nowDate.setHours(nowTime.split(":")[0]);
            nowDate.setMinutes(nowTime.split(":")[1]);
            nowDate.setSeconds(nowTime.split(":")[2]);

            let limitDate = new Date(currentDate.getTime());
            limitDate.setHours(limitTime.split(":")[0]);
            limitDate.setMinutes(limitTime.split(":")[1]);
            limitDate.setSeconds(limitTime.split(":")[2]);  

         
            //console.log('nowDate '+nowDate);
            //.log('limitDate '+limitDate);
        
            if (nowDate > limitDate){
                if(String(this.date.weekday).toLowerCase() === 'hoy'){
                    this.disabledMatutino = true;
                    this.time = "N";
                    if(this.on){
                        this.$refs.btnTime2.$el.classList.add("time-option-active");
                    }else{
                        this.$refs.btnTime2.$el.classList.remove("time-option-active");
                    }
                   
                }else{
                    this.disabledMatutino = false;
                    if(this.on){
                        this.$refs.btnTime1.$el.classList.add("time-option-active");
                    }else{
                        this.$refs.btnTime1.$el.classList.remove("time-option-active");
                    }
                }
            }else{
                this.disabledMatutino = false;
                
                if(this.on){
                    
                    setTimeout(() => {
                            this.$refs.btnTime1.$el.classList.add("time-option-active");
                    }, 50);
                    
                    
                }else{
                    
                    this.$refs.btnTime1.$el.classList.remove("time-option-active");
                }
            }
            //console.log('checkLimitTime')
         },
        onDateChange(){
            this.on = ! this.on;
            if(this.on){
                this.$refs.btnDate.$el.classList.add("raffle-option-active");
               
            }else{
                this.$refs.btnDate.$el.classList.remove("raffle-option-active"); 
                this.$refs.btnTime1.$el.classList.remove("time-option-active");
                this.$refs.btnTime2.$el.classList.remove("time-option-active");
            }
            this.checkLimitTime(); 
            this.$emit('on-date-change',this.on,this.index,this.time);
        },
        onTimeChange(time){
            this.time = time;

            if(this.time === "M"){
                this.$refs.btnTime1.$el.classList.add("time-option-active");                 
                this.$refs.btnTime2.$el.classList.remove("time-option-active"); 
                 
            }else{
                this.$refs.btnTime2.$el.classList.add("time-option-active");                 
                this.$refs.btnTime1.$el.classList.remove("time-option-active"); 
            }
            this.$emit('on-time-change',this.time,this.index);
        },
        forceOn(){
           
            this.on = true;
            this.$refs.btnDate.$el.classList.add("raffle-option-active");
            this.checkLimitTime();
        },
        forceOff(){
            
            this.on = false;
            this.$refs.btnDate.$el.classList.remove("raffle-option-active");
            this.$refs.btnTime1.$el.classList.remove("time-option-active");
            this.$refs.btnTime2.$el.classList.remove("time-option-active");
        }
    }
}
</script>

<style>

</style>