<template>
  <div class="game-container">
   
    <div class="toPrint-nt">
           <img
            id="ticketImage"
            alt="Logo"
            style="max-width:100%;"
            src="/static/dist/img/nuevosTiemposLogo.2dd7955d.png"
           
            
          />
          <img
            id="apkTicketImage"
            alt="Logo"
            style="max-width:100%;"
            src="/static/dist/img/nuevosTiemposBNW.png"
           
            
          />

          <canvas id="apkLogoCanvas" width="150" height="150" />
          
          <div id="printTicket" class="print-ticket">
             

            <table class='table-ticket-numbers'>
                <tr>
                  <td class="index-column font-weight-bold">#</td>
                  <td class="game-column font-weight-bold">Juego</td>
                  <td class="numbers-column font-weight-bold">Número</td>                
                  <td class="date-column font-weight-bold">Fecha</td>                
                  <td class="bet-column font-weight-bold">Monto</td>
                  <td class="reventado-column font-weight-bold">R</td>
                <tr>
                <tr  v-for="(num, index) of selectedNumbers"
                :key="index">
                  <td class="index-column">{{ getIndex(index) }}</td>
                  <td class="game-column"> {{ num.type | capitalized }} {{ getTicketBlindRooster(num)}}</td>
                  <td class="numbers-column"><span class="ticket-number"  >{{ num.number }}</span></td>                  
                  <td class="date-column">{{ num.date }}</td>                  
                  <td class="bet-column"> {{ num.bet | addCurrency(currency, true) }}</td>
                  <td v-if="num.reventadoBet != '' " class="reventado-column"> {{ num.reventadoBet | addCurrency(currency, true) }}</td>
                  <td v-else class="reventado-column"> -- </td>
                </tr>
              
            </table>
            <table class="table-ticket-totals">
                <tr>
                  <td class="total-label">
                    Monto Total:
                  </td>
                  <td class="total-amount">
                    {{ getTotal | addCurrency(currency, true) }}
                  
                  </td>
                </tr>
                <tr>
                  <td class="total-label">
                    Cantidad de sorteos:
                  </td>
                  <td class="total-amount">
                    {{ selectedNumbers.length }}
                  </td>
                </tr>
              
            </table>
            <div class="good-luck-message">
              <p>¡ MUCHA SUERTE !</p>
            </div>
            <table class="table-ticket-info">
                <tr>
                  <td class="total-label">
                    Fecha jugada:
                  </td>
                  <td class="total-amount">
                    {{ getPrintDate() }}
                  </td>
                </tr>
                <tr>
                  <td class="total-label">
                    Terminal:
                  </td>
                  <td class="total-amount">
                    1208-001
                  </td>
                </tr>
            </table>
            <qrcode-vue class="qr-ticket" :value="qrValue" :size="qrSize" :level="qrLevel" />
            <p>{{qrValue}}</p>
            <p>Fecha de impresión: {{getPrintDate()}}</p>
          </div>
    </div>
    <div v-if="!portrait" class="landscape">
      <v-dialog persistent v-model="printDialog" width="40%">
         <div v-if="printing"  class="printing-card">
          <v-icon class="print-icon">mdi-printer</v-icon> 
          <p>Imprimiendo</p>     
          <div class="stage">
              <div class="dot-flashing"></div>
          </div>
        </div>
        <v-card class="print-dialog">
          <div class="dialog-title">
            <p class="dialog-label">{{ lblPrintDialogTitle }}</p>
          </div>

          <div class="info-panel-display">
            <div
              v-for="(num, index) of selectedNumbers"
              :key="index"
              class="info-panel-number"
            >
              <div class="number-container">
                <div class="index">{{ getIndex(index) }}</div>
                <div class="number">{{ num.number }}</div>
              </div>

              <div v-if="num.blindRooster === ''" class="type">
                {{ num.type | capitalized }}
                <div class="date">{{ num.date }}</div>
              </div>
              <div v-else class="type">
                {{ num.type | capitalized }} ({{ num.blindRooster }})
                <div class="date">{{ num.date }}</div>
              </div>

              <div v-if="num.reventadoBet === ''" class="bet">
                {{ num.bet | addCurrency(currency, true) }}
              </div>
              <div v-else class="bet">
                {{ num.bet | addCurrency(currency, true) }}
                <p class="label-reventado">(R)</p>
                {{ num.reventadoBet | addCurrency(currency, true) }}
              </div>
            </div>
          </div>
          <v-divider class="dialog-divider"></v-divider>
          <div class="resumen-dialog">
            <p class="dialog-resumen-title">{{ lblResumeDialogtitle }}</p>
            <!-- <p class="dialog-resumen-totals">{{ lblDialogRafflesTimes }}</p>
          <p class="dialog-resumen-value">{{ getRaffleTimes }}</p>
          <p class="dialog-resumen-totals">{{ lblDialogRafflesDays }}</p>
          <p class="dialog-resumen-value">{{ getRaffleDays }}</p> -->
            <p class="dialog-resumen-totals">
              {{ lblDialogTotal | addCurrency(currency, false) }} {{ getTotal }}
            </p>
            <!-- <p class="dialog-resumen-value">{{ getTotal }}</p> -->
          </div>
          <div class="controls-dialog">
            <v-btn color="error" class="button-dialog" @click="onDialogCancel">
              {{ lblCancelar }}
            </v-btn>
            <v-btn
              color="success"
              class="button-dialog"
              @click="onDialogAccept"
            >
              {{ lblAceptar }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div class="game-col-1">
       
        <div class="info-panel-container">
          <info-panel
            ref="info"
            :numbers="selectedNumbers"
            :currency="currency"
            :totalBet="getTotal"
            :raffleDays="getRaffleDays"
            :raffleTimes="getRaffleTimes"
            v-on:clear-numbers="onClear"
            v-on:delete-number="onDelete"
          />
        </div>
      </div>
      <div class="game-col-2">
        <div class="display-panel-container">
          <display-panel
            ref="displayPanel"
            :currentNumber="currentNumber"
            :disabledBetTypes="disabledBetTypes"
            :amountBet="amountBet"
            :currency="currency"
            :showBetConfirm="showBetConfirm"
            :disabledConfirmarBet="disabledConfirmarBet"           
            :reset="reset"
            :alertType="alertType"
            :alertText="alertText"
            :reventadoAmountBet="reventadoAmountBet"
            v-on:update-raffle-type="onUpdateRaffleType"
            v-on:on-reventado-change="onUpdateRevetando"
            v-on:on-confirmar-bet="onConfirmarBet"
            v-on:on-bet="onBet"
            v-on:on-reventado-bet="onReventadoBet"
            v-on:on-cancel-reventado="onDeleteReventadoBet"
          />
        </div>
        <div class="control-panel-container">
          <control-panel
            :days="days"            
            :times="times"
            :valueButtons="valueButtons"
            :betButtons="betButtons"
            :currency="currency"
            :reset="resetDefault"
            :setDefaultBet="defaultBet"
            :deactivateDefaultBets="deactivateDefaultBets"
            :setDefaultDay="defaultDay"
            :setDefaultTime="defaultTime"
            :resetCustomBet="resetCustomBet"
            :acceptDisabled="acceptDisabled"
            :showCancel="showCancel"
            :disabledBetAmounts="disabledBetAmounts"
            :disabledControls="disabledControls"
            :disabledValueControls="disabledValueControls"
            :disabledDateControls="disabledDateControls"
            :disabledMatutino="disabledMatutino"
            :lblAceptar="lblControlAceptar"
            :matutinoTime="MATUTINO_TIME"
            :nocturnoTime="NOCTURNO_TIME"
            :currentRaffleDay="currentRaffleDay"
            :disabledPrint="getPrintDisabledStatus()"
            v-on:keyboard-update="onKeyboardUpdate"
            v-on:update-bet="onUpdateBet"
            v-on:update-custom-bet="onUpdateCustomBet"
            v-on:on-update-raffle-day="onUpdateRaffleDay"
            v-on:on-update-raffle-time="onUpdateRaffleTime"
            v-on:on-blind-rooster-tap="onBlindRooster"
            v-on:on-aceptar-tap="onAceptar"
            v-on:print="onPrint"
            v-on:cancel="onCancel"
          />
        </div>
        <div class="footer">
          <p class="footer_text">
            Powered by 
          </p>
           <img
           
            alt="Logo"
            style="max-width:100%;"
            class="logo-footer"
            src="/static/dist/img/logo_footer.svg"
           
            
          />
          <p class="footer_text">{{ lblFooter }}</p>
        </div>
        
      </div>
    </div>









    <!-- PORTRAIT -->
    <div v-if="portrait" class="portrait-nt">
      <v-dialog persistent v-model="printDialog" width="100%">

          <div v-if="printing"  class="printing-card">
          <v-icon class="print-icon">mdi-printer</v-icon> 
          <p>Imprimiendo</p>     
          <div class="stage">
              <div class="dot-flashing"></div>
          </div>
        </div>
      
        <v-card class="print-dialog-portrait">
          <div class="dialog-title">
            <p class="dialog-label">{{ lblPrintDialogTitle }}</p>
          </div>

          <div class="info-panel-display">
            <div
              v-for="(num, index) of selectedNumbers"
              :key="index"
              class="info-panel-number"
            >
              <div class="number-container">
                <div class="index">{{ getIndex(index) }}</div>
                <div class="number">{{ num.number }}</div>
              </div>

              <div v-if="num.blindRooster === ''" class="type">
                {{ num.type | capitalized }}
                <div class="date">{{ num.date }}</div>
              </div>
              <div v-else class="type">
                {{ num.type | capitalized }} ({{ num.blindRooster }})
                <div class="date">{{ num.date }}</div>
              </div>

              <div v-if="num.reventadoBet === ''" class="bet">
                {{ num.bet | addCurrency(currency, true) }}
              </div>
              <div v-else class="bet">
                {{ num.bet | addCurrency(currency, true) }}
                <p class="label-reventado">(R)</p>
                {{ num.reventadoBet | addCurrency(currency, true) }}
              </div>
            </div>
          </div>
          <v-divider class="dialog-divider"></v-divider>
          <div class="resumen-dialog">
            <p class="dialog-resumen-title">{{ lblResumeDialogtitle }}</p>
            <!-- <p class="dialog-resumen-totals">{{ lblDialogRafflesTimes }}</p>
          <p class="dialog-resumen-value">{{ getRaffleTimes }}</p>
          <p class="dialog-resumen-totals">{{ lblDialogRafflesDays }}</p>
          <p class="dialog-resumen-value">{{ getRaffleDays }}</p> -->
            <p class="dialog-resumen-totals">
              {{ lblDialogTotal | addCurrency(currency, false) }} {{ getTotal }}
            </p>
            <!-- <p class="dialog-resumen-value">{{ getTotal }}</p> -->
          </div>
          <div class="controls-dialog">
            <v-btn color="error" class="button-dialog" @click="onDialogCancel">
              {{ lblCancelar }}
            </v-btn>
            <v-btn
              color="success"
              class="button-dialog"
              @click="onDialogAccept"
            >
              {{ lblAceptar }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="showPdf" width="500">
        <pdf v-if="showPdf" :pdf="pdfUrl"></pdf>
        <v-btn>Cerrar</v-btn>
      </v-dialog> -->
      <div class="portrait-display-panel-container">
        <display-panel-portrait
          ref="displayPanelPortrait"
          :currentNumber="currentNumber"
          :amountBet="amountBet"
          :currency="currency"
          :showBetConfirm="showBetConfirm"
          :disabledConfirmarBet="disabledConfirmarBet"          
          :reset="reset"
          :currentRaffleType="currentRaffleType"
          :reventadoAmountBet="reventadoAmountBet"
          v-on:on-reventado-change="onUpdateRevetando"
          v-on:on-confirmar-bet="onConfirmarBet"
          v-on:on-bet="onBet"
          v-on:on-reventado-bet="onReventadoBet"
        />
      </div>
      <div class="portrait-info-panel-container">
        <info-panel-portrait
          ref="infoPortrait"
          :numbers="selectedNumbers"
          :currency="currency"
          :totalBet="getTotal"
          :raffleDays="getRaffleDays"
          :raffleTimes="getRaffleTimes"
          v-on:clear-numbers="onClear"
          v-on:delete-number="onDelete"
        />
      </div>
      <div class="portrait-control-panel-container">
        <control-panel-portrait
          :days="days"
          :times="times"
          :valueButtons="valueButtons"
          :betButtons="betButtons"
          :currency="currency"
          :alertType="alertType"
          :alertText="alertText"
          :reset="resetDefault"
          :setDefaultBet="defaultBet"
          :deactivateDefaultBets="deactivateDefaultBets"
          :setDefaultDay="defaultDay"
          :clearDays="clearDays"
          :setDefaultTime="defaultTime"            
          :showCancel="showCancel"
          :showAlertArrow="showAlertArrow"
          :disabledBetAmounts="disabledBetAmounts"
          :disabledControls="disabledControls"
          :disabledValueControls="disabledValueControls"
          :disabledDateControls="disabledDateControls"
          :disabledMatutino="disabledMatutino"
          :lblAceptar="lblControlAceptar"
          :resetCustomBet="resetCustomBet"
          :acceptDisabled="acceptDisabled"
          :matutinoTime="MATUTINO_TIME"
          :nocturnoTime="NOCTURNO_TIME"
          :currentRaffleDay="currentRaffleDay"
          :currentRaffleTime="currentRaffleTime"
          :disabledPrint="getPrintDisabledStatus()"
          v-on:keyboard-update="onKeyboardUpdate"
          v-on:update-bet="onUpdateBet"
          v-on:update-custom-bet="onUpdateCustomBet"
          v-on:on-update-raffle-day="onUpdateRaffleDay"
          v-on:on-update-raffle-time="onUpdateRaffleTime"
          v-on:on-blind-rooster-tap="onBlindRooster"
          v-on:on-aceptar-tap="onAceptar"
          v-on:print="onPrint"
          v-on:update-raffle-type="onUpdateRaffleType"
          v-on:cancel="onCancel"
          v-on:show-dates="onShowDates"
          v-on:acept-dates="onAceptDates"
          v-on:cancel-dates="onCancelDates"
        />
      </div>
    </div>
  </div>
</template>
<script>
import '../assets/css/nuevosTiempos.scss';
import InfoPanel from "../components/nuevosTiempos/InfoPanelNT.vue";
import DisplayPanel from "../components/nuevosTiempos/DisplayPanelNT.vue";
import ControlPanel from "../components/nuevosTiempos/ControlPanelNT.vue";
import InfoPanelPortrait from "../components/nuevosTiempos/InfoPanelPortraitNT.vue";
import DisplayPanelPortrait from "../components/nuevosTiempos/DisplayPanelPortraitNT.vue";
import ControlPanelPortrait from "../components/nuevosTiempos/ControlPanelPortraitNT.vue";
import QrcodeVue from 'qrcode.vue'
import BmpEncoder from "../helpers/bmpEncoder.js"
import TicketData from "../helpers/ticketData.js"



import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"

import { mapState } from "vuex";

export default {
  components: {
    "info-panel": InfoPanel,
    "display-panel": DisplayPanel,
    "control-panel": ControlPanel,
    "info-panel-portrait": InfoPanelPortrait,
    "display-panel-portrait": DisplayPanelPortrait,
    "control-panel-portrait": ControlPanelPortrait,
    QrcodeVue
   
    
  },
  data() {
    return {
      game: null,
      printing:false,
      MAX_BET: 2000000,
      MIN_BET: 100,
      MAX_NUMBERS: 10,
      MATUTINO_TIME: "12:00",
      NOCTURNO_TIME: "19:00",
      currency: "₡",
      selectedNumbers: [],
      valueButtons: [],
      betButtons: [],
      days: [],
      times: [],
      currentNumber: "",
      currentRaffleType: "",
      currentRaffleDay: "",
      currentRaffleTime: "",
      currentPadTagert: "",
      reventado: false,
      reset: false,
      resetDefault: false,
      amountBet: 0,
      reventadoAmountBet: '-',
      alertType: "info",
      alertText: "",
      printDialog: false,
      lblAceptar: "Aceptar",
      lblCancelar: "Cancelar",
      lblPrintDialogTitle: "¿Desea imprimir el ticket?",
      lblDialogRafflesDays: "Dias: ",
      lblDialogRafflesTimes: "Horario: ",
      lblDialogTotal: "Total en ",
      lblResumeDialogtitle: "Resumen:",
      lblFooter: "",
      acceptDisabled: false,
      disabledConfirmarBet: true,     
      duplicateNumber: false,
      blindRooster: false,
      showBetConfirm: false,
      resetCustomBet: "",
      previousBet: "",
      printPdf: true,
      portrait: false,
      showPdf: false,


      lblControlAceptar:'Ingresar',
      confirmBet:false,
      BET_TYPE_STATE:'betTypeState',
      BET_AMOUNT_STATE:'betAmountState',
      REVENTADO_BET_AMOUNT_STATE:'reventadoBetAmountState',
      RAFFLE_NUMBER_STATE:'raffleNumberState',         
      RAFFLE_DATE_STATE:'raffleDateState',         
      INSERT_RAFFLE_STATE:'insertDateState',         
      currentState:null,
      disabledBetTypes:false,
      disabledBetAmounts:false,
      disabledControls:false,
      disabledValueControls:false,
      disabledDateControls:false,
      customBet:false,
      selectedDays:[],
      defaultBet:false,
      defaultDay:false,
      defaultTime:false,
      showCancel:false,
      showAlertArrow:false,
      deactivateDefaultBets:false,
      qrValue:'125646-45616465',
      qrSize:130,
      qrLevel:'H',
      disabledMatutino:false,
      clearDays:false,
      settingReventados:false,      

    };
  },
  created() {
    window.addEventListener("resize", this.resolutionUpdated);
    this.resolutionUpdated();
    this.currentPadTagert = "currentNumber";
    this.valueButtons = [
      "7",
      "8",
      "9",
      "4",
      "5",
      "6",
      "1",
      "2",
      "3",
      "<",
      "0",
      "BORRAR",
    ];
    this.betButtons = ["100", "200", "300", "400", "500","1000", "2000"];
    this.times = ["m", "n"];
    this.days = ["HOY", "LUN", "MAR", "MIÉ", "JUE", "VIE", "SAB", "DOM"];   
    this.amountBet = 100;
    this.currentNumber = "";
    this.alertText = "";
    this.currentRaffleType = "Exacto";
    this.lblFooter = "v" + this.version;
   
    this.setDates();
   
    this.currentState = this.BET_TYPE_STATE;
    this.showCancel = false;
    if(!this.checkLimitTime()){
       this.currentRaffleTime = this.times[1];
    }else{
       this.currentRaffleTime = this.times[0];
    }
    this.currentRaffleDay = this.days[0];
    this.selectedDays = [this.currentRaffleDay];
    this.selectedDays[0].time = this.currentRaffleTime;
  },
  destroyed() {
    window.removeEventListener("resize", this.resolutionUpdated);
  },
  mounted() {
    console.log('NuevosTiempos ',this.onApk ,this.apkPrinterReady );
    //alert('NuevosTiempos onApk '+this.onApk+' apkPrinterReady '+this.apkPrinterReady)
    if(this.onApk && !this.apkPrinterReady){
        this.$printer.initializePrinter(this.onApkPrinterReady,this.onApkPrinterReject);
    }
   
    //console.log('Mounted '+this.$printer.activated());
    this.$store.dispatch("setTitle", "Nuevos Tiempos");
    this.setRaffleAlert();
    if (document.getElementById("version")) {
      var element = document.getElementById("version");
      var version = element.value;
      this.$store.dispatch("setVersion", version);
      this.lblFooter = "v" + this.version;
      element.parentNode.removeChild(element);
    }
    if (document.getElementById("printPdf")) {
      var element = document.getElementById("printPdf");
      if (element.value == "True") {
        this.printPdf = true;
      } else {
        this.printPdf = false;
      }
      element.parentNode.removeChild(element);
    }

    this.defaultTime = !this.defaultTime;
    this.defaultDay = !this.defaultDay;
    this.defaultBet = !this.defaultBet;



    


  },
  computed: {
    ...mapState({
      pdfUrl: (state) => state.pdfUrl,
      version: (state) => state.version,
      onApk: (state) => state.onApk,
      apkPrinterReady: (state) => state.apkPrinterReady,
    }),
    getTotal() {
      var total = 0;
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        total += Number(this.selectedNumbers[i].bet);
        if (this.selectedNumbers[i].reventadoBet != "") {
          total += Number(this.selectedNumbers[i].reventadoBet);
        }
      }

      if (total == 0) {
        return "";
      } else {
        return total;
      }
    },
    getRaffleDays() {
      var days = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var day = String(this.selectedNumbers[i].raffleDay).toLowerCase();
        day = day.charAt(0).toUpperCase() + day.slice(1);
        if (days.indexOf(day) < 0) {
          days.push(day);
        }
      }
      if (days.length == 0) {
        return "";
      } else {
        return days.toString();
      }
    },
    getRaffleTimes() {
      var times = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var time = String(this.selectedNumbers[i].raffleTime).toLowerCase();
        time = time.charAt(0).toUpperCase() + time.slice(1);
        if (times.indexOf(time) < 0) {
          times.push(time);
        }
      }
      if (times.length == 0) {
        return "";
      } else {
        return times.toString();
      }
    },
    getRaffles() {
      var raffes = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var type = String(this.selectedNumbers[i].type).toLowerCase();
        var raffleType = "";
        //Para pasarle al ticket la abreviacion
        switch (String(type).toLowerCase()) {
          case "exacto":
            raffleType = "EXA";
            break;
          case "primero":
            raffleType = "PRI";
            break;
          case "reversible":
            raffleType = "REV";
            break;
          case "terminación":
            raffleType = "TER";
            break;

          default:
            break;
        }
        if (raffes.indexOf(raffleType) < 0) {
          raffes.push(raffleType);
        }
      }

      return raffes;
    },
  },
  filters: {
    capitalized(val) {
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    addCurrency(val, currency, before) {
      if (val != "0" && val != "") {
        if (before) {
          return currency + " " + val;
        } else {
          return val + " " + currency;
        }
      } else {
        return val;
      }
    },
  },
  methods: {
    onApkPrinterReady(){
      console.log('onApkPrinterReady')
     // alert('NuevosTiempos onApkPrinterReady')
      this.$store.dispatch("setApkPrinterReady", true);
    },
    onApkPrinterReject(){
      console.log('onApkPrinterReject')
     // alert('NuevosTiempos onApkPrinterReject')
      this.$store.dispatch("setApkPrinterReady", false);
    },
    getTicketBlindRooster(num){
      if(num.blindRooster.length === 0){
        return '';
      }else{
        return '('+num.blindRooster+')';
      }
    },
    checkRaffleTimes(){

  
   
      if (!this.checkLimitTime() && this.selectedDays.length === 1 && String(this.selectedDays[0].weekday).toLowerCase() === 'hoy') {
        this.disabledMatutino = true;
        
      } else {
        this.disabledMatutino = false;
       
      }
      //this.defaultTime = !this.defaultTime;
    },

    checkLimitTime(){
      let currentDate = new Date();      
      let hours = currentDate.getHours();
      let minutes = currentDate.getMinutes();
      let seconds = currentDate.getSeconds();
      let nowTime = hours+':'+minutes+':'+seconds;
      let limitTime = this.MATUTINO_TIME+':00';    

      let nowDate = new Date(currentDate.getTime());
      nowDate.setHours(nowTime.split(":")[0]);
      nowDate.setMinutes(nowTime.split(":")[1]);
      nowDate.setSeconds(nowTime.split(":")[2]);

      let limitDate = new Date(currentDate.getTime());
      limitDate.setHours(limitTime.split(":")[0]);
      limitDate.setMinutes(limitTime.split(":")[1]);
      limitDate.setSeconds(limitTime.split(":")[2]);  

         
     /*  console.log('nowDate '+nowDate);
      console.log('limitDate '+limitDate);
      console.log('this.selectedDays.length '+this.selectedDays.length);
      console.log('this.selectedDays[0].weekday '+this.selectedDays[0].weekday); */

      if (nowDate > limitDate){
        return false;
      }else{
        return true;
      }
    },
    onCreateTicket(){
        //console.log('onCreateTicket '+this.apkPrinterReady)  

       
        
        if(this.onApk){
          try {

            this.apkPrint();

          } catch (error) {

            alert('Print Error '+error);

          } 
        }else{

          this.printing = true;

          const minDocHeight = 80;
          const maxDocHeight = 110;
          let docWidth = 55; 
          let docHeight = maxDocHeight; 

          if(this.selectedNumbers.length < this.MAX_NUMBERS){
            docHeight = minDocHeight + (this.selectedNumbers.length  * 3);
          }
  
          let doc = new jsPDF('p', 'mm', [docHeight, docWidth]); //310mm high 55mm wide
          let ref = this;
          let scaleCanvas = null;      
          

          //console.log('scaleCanvas '+scaleCanvas)
          
          if(!this.portrait){
            scaleCanvas = 1.5;
          }               


          html2canvas(document.getElementById("printTicket"),{scale:scaleCanvas, width: document.getElementById("printTicket").offsetWidth,
          height: document.getElementById("printTicket").offsetHeight}).then(function(canvas) {

              const imgData = canvas.toDataURL('image/png');            
              //console.log('onCreateTicket '+canvas.width);
              //console.log('onCreateTicket '+canvas.height);

              const pageWidth = doc.internal.pageSize.getWidth();
              const pageHeight = doc.internal.pageSize.getHeight();

              const widthRatio = pageWidth / canvas.width;
              const heightRatio = pageHeight / canvas.height;
              const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

              const canvasWidth = canvas.width * ratio;
              const canvasHeight = canvas.height * ratio;

              const marginX = (pageWidth - canvasWidth) / 2;
              const marginY = (pageHeight - canvasHeight) / 2;

              let imageHeight = pageWidth * 0.3;
              let imageWidth = pageWidth * 0.3;

              const imageMarginX = (pageWidth - imageWidth) / 2;

              const fileName = 'NuevosTiempos-'+ref.getPrintDate()+'.pdf';
              const gap = 1;
              
              doc.addImage(document.getElementById("ticketImage"), 'PNG', imageMarginX, gap,imageWidth,imageHeight);
              doc.addImage(imgData, 'PNG', 0, imageHeight+gap,canvasWidth,canvasHeight);

              doc.save(fileName);


              setTimeout(() => {
               
                ref.printing = false;  
                ref.printDialog = false;          
                ref.onClear();
              }, 1000);
            
              
            
          }
          );
       

        }
         
       
    },
    apkPrint(){


      //console.log('sendImageToPrint')

      this.printing = true;

      let c = document.getElementById("apkLogoCanvas");
      let ctx = c.getContext("2d");
      let img = document.getElementById("apkTicketImage");
      
      ctx.drawImage(img, 0, 0,document.getElementById("apkTicketImage").offsetWidth,document.getElementById("apkTicketImage").offsetHeight);
      let logoData = c.toDataURL('image/png').split(';base64,')[1]


      let ticket =  new TicketData();

    
      ticket.logoData       = logoData;     
      ticket.totalAmount    = 'Total: '+this.currency+this.getTotal;
      ticket.numbersAmount  = 'Cantidad de sorteos: '+this.selectedNumbers.length;
      ticket.gameDate       = 'Fecha jugada: '+this.getPrintTicketDate();
      ticket.terminalData   = 'Terminal: 1208-001';
      ticket.qrValue        = this.qrValue;
      ticket.printDate      = 'Fecha de imp. '+this.getPrintTicketDate();
      ticket.message        = '¡ MUCHA SUERTE !';
      ticket.logoWidth      = 150;
      ticket.logoHeight     = 150;
      ticket.fontSize       = 23;

      
      ticket.numbers = [];

      for (let i = 0; i < this.selectedNumbers.length; i++) {

        let raffle = this.selectedNumbers[i];
        let reventadoBet = 'R ----';
        let type = raffle.type;
        let bet = String(this.currency + raffle.bet);
        let date = String(raffle.date).replace(/\s/g, '');
        let num = raffle.number;

        if(raffle.blindRooster){
          type += ' GT';
        }

        if(raffle.reventadoBet != ''){
           reventadoBet = String('R'+this.currency + raffle.reventadoBet);
        }

        while(type.length < 6){
          type += ' ';
        }
        while(bet.length < 5){
          bet += ' ';
        }
        while(reventadoBet.length < 6){
          reventadoBet += ' ';
        }
        while(num.length < 2){
          num += ' ';
        }

        let row = this.getIndex(i)+' '+type+' '+num+' '+date+' '+bet+' '+reventadoBet;
        ticket.numbers.push(row);
        
      }

     

      try {

        let ref = this;
        this.$printer.printTicket(ticket,function(){
            //console.log('Print end');
            ref.printing = false;
            ref.printDialog = false;          
            ref.onClear();
        },function(){
            ref.printing = false;
        });

        
      } catch (error) {
         this.printing = false;
         alert('Print Error '+error);
      }

     
     

     
     /*  try {
        var ref = this;
        html2canvas(document.getElementById("printTicket"),{width: document.getElementById("printTicket").offsetWidth,
        height: document.getElementById("printTicket").offsetHeight}).then(function(canvas) {



            ref.$printer.printTicket(logoData,canvas.toDataURL('image/png').split(';base64,')[1],function(){
             
            });


          }
        );
      } catch (error) {
         alert('Print Error '+error);
      } */
      
    },
    onAceptDates(){
      this.onAceptar();
    },
    onCancelDates(){
      //this.showAlertArrow = true;
      this.selectedDays = [this.days[0]];
      this.defaultDay = !this.defaultDay;
    },
    onDeleteReventadoBet(){
       this.reventadoAmountBet = '-';
    },
    onCancel(){
        this.showCancel = false;
        this.reventadoAmountBet = '-';
        this.currentState = this.RAFFLE_DATE_STATE;
        this.defaultDay = !this.defaultDay;
        this.defaultTime = !this.defaultTime;
       
        //this.checkRaffleTimes();
    },
  
    resolutionUpdated() {
      var width = Number(window.screen.availWidth);
      var height = Number(window.screen.availHeight);
      //console.log("Resolution Updated " + width + "X" + height);
      if (width < height || width < 1000) {
        this.portrait = true;
        //console.log("Portrait");
      } else {
        this.portrait = false;
        //console.log("Landscape");
      }
    },
    onBet() {
      this.lblControlAceptar = "Confirmar"
      this.confirmBet = true;
      this.disabledConfirmarBet = true;
      this.acceptDisabled = false;
      this.currentPadTagert = "amountBet";
      //this.showBetConfirm = true;
      this.amountBet = "-";
      this.alertText = "Confirme valor de apuesta";
    },
    onReventadoBet() {
      if (this.reventado) {
        this.settingReventados = true;
        //this.currentPadTagert = "reventadoAmountBet";
        this.reventadoAmountBet = "-";
        this.alertType = 'info';
        this.alertText = "Confirme un valor de apuesta para REVENTADOS";
        this.lblControlAceptar = "Confirmar";
      }
    },
    onConfirmarBet() {
      if (this.validateBet()) {
        this.showBetConfirm = false;
        this.currentPadTagert = "currentNumber";
        //this.resetCustomBet = this.previousBet;
        this.disabledConfirmarBet = true;        
        this.validateNumber();
        this.confirmBet = false;
        this.lblControlAceptar = 'Ingresar';
      }
    },

    validateTotal(total, equal) {
      if (equal) {
        if (Number(total) >= this.MAX_BET) {
          this.alertText = "Apuesta máxima: " + this.MAX_BET;
          this.acceptDisabled = true;
        } else {
          if (this.selectedNumbers.length < this.MAX_NUMBERS) {
            if (this.currentNumber != "") {
              this.acceptDisabled = false;
            }
          } else {
            this.alertText = "No se pueden jugar mas de 10 números";
          }
          //this.setRaffleAlert();
        }
      } else {
        if (Number(total) > this.MAX_BET) {
          this.alertText = "Apuesta máxima: " + this.MAX_BET;
          this.acceptDisabled = true;
        } else {
          if (this.selectedNumbers.length < this.MAX_NUMBERS) {
            if (this.currentNumber != "") {
              this.acceptDisabled = false;
            }
          }
          //this.setRaffleAlert();
        }
      }
    },
    duplicateBet() {
      let exits = false;
       
      for (let e = 0; e < this.selectedDays.length; e++) {


        let testNumber = {
          number:this.currentNumber,
          type:this.currentRaffleType,
          date:this.selectedDays[e].date,
          time:this.selectedDays[e].time
        }
        
        if (this.portrait) {
          testNumber.type = this.currentRaffleType
            .substring(0, 3)
            .toUpperCase();
        } else {
          testNumber.type = this.currentRaffleType;
        }
        
     

        let time = "";
        if (String(testNumber.time).toLowerCase() == "n") {
          time = "N";
        } else {
          time = "M";
        }
        let day = testNumber.date + " " + time;
        testNumber.date = day;

        //console.log('duplicateBet '+this.currentNumber+' '+testNumber.number+' '+testNumber.type+' '+testNumber.date+' '+testNumber.time);
        
        for (let i = 0; i < this.selectedNumbers.length; i++) {
          //console.log('duplicateBet  '+this.selectedNumbers[i].number+' '+this.selectedNumbers[i].type+' '+this.selectedNumbers[i].date);
            if (
              this.selectedNumbers[i].number == testNumber.number &&
              this.selectedNumbers[i].type == testNumber.type &&
              this.selectedNumbers[i].date == testNumber.date
            ) {
              exits = true;
            }
        }
        
      }
     
      return exits;
    },
    onPrint() {
      this.qrSize = document.getElementById('printTicket').offsetWidth * 0.270; 
      this.printDialog = true;
    },
    onDialogAccept() {
      this.onCreateTicket();
     /*  this.printDialog = false;
      var printItems = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var printItem = {
          number: this.selectedNumbers[i].number,
          bet: this.currency + "" + this.selectedNumbers[i].bet,
          reventadoBet: this.selectedNumbers[i].reventadoBet,
          type: this.selectedNumbers[i].type,
          blindRooster: this.selectedNumbers[i].blindRooster,
          raffleDay: this.selectedNumbers[i].raffleDay,
          raffleTime: this.selectedNumbers[i].raffleTime,
          date: this.selectedNumbers[i].date,
        };

        if (printItem.reventadoBet != "") {
          printItem.reventadoBet = this.currency + "" + printItem.reventadoBet;
        }

        printItems.push(printItem);
      }

      var params = {
        ticketItems: printItems,
        total: this.currency + "" + this.getTotal,
        raffleDays: this.getRaffleDays,
        raffles: this.getRaffles,
        raffleTimes: this.getRaffleTimes,
        game:'nuevosTiempos'
      };
      if (this.printPdf) {
        this.$store.dispatch("printTicket", params).then((res) => {
          console.log("Impreso " + this.pdfUrl);
          this.showPdf = true;
          this.onClear();
        });
      } else {
        this.sendToQuickPrinter(params);
        this.onClear();
      } */
    },
    sendToQuickPrinter(params) {
      //console.log("sendToQuickPrinter");
      var text = this.createTicket(params);
      var textEncoded = encodeURI(text);
      window.location.href =
        "intent://" +
        textEncoded +
        "#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;";
    },
    createTicket(params) {
      var ticketText = "";
      var space = " ";

      //Logo
      ticketText += "<center><image>../img/nuevosTiemposLogo.fffadb05.png<br>";

      var tableDelimiter = ";;";
      //Numbers headers
      ticketText +=
        "<center>" +
        this.forceLength("Juego", 10) +
        tableDelimiter +
        this.forceLength("Numero", 10) +
        tableDelimiter +
        this.forceLength("Fecha", 10) +
        tableDelimiter +
        this.forceLength("Monto", 10) +
        tableDelimiter +
        this.forceLength("R", 10);
      ("<br>");

      //Numbers
      for (let i = 0; i < params.ticketItems.length; i++) {
        var number = this.forceLength(params.ticketItems[i].number, 10);
        var bet = this.forceLength(params.ticketItems[i].bet, 10);
        var type = this.forceLength(params.ticketItems[i].type, 10);
        var reventadoBet = " ";
        if (params.ticketItems[i].reventadoBet != "") {
          reventadoBet = params.ticketItems[i].reventadoBet;
        }
        reventadoBet = this.forceLength(reventadoBet, 10);
        var blindRooster = params.ticketItems[i].blindRooster;
        var date = this.forceLength(params.ticketItems[i].date, 10);
        var index = this.getIndex(i);
        var game = index + " " + type.substring(0, 3).toUpperCase();
        game = this.forceLength(game, 10);

        //console.log('Game ticket '+game);

        if (blindRooster != "") {
          game += " " + blindRooster;
        }
        ticketText +=
          "<center>" +
          game +
          tableDelimiter +
          number +
          tableDelimiter +
          date +
          tableDelimiter +
          bet +
          tableDelimiter +
          reventadoBet +
          "<br>";
      }

      //Raffles amount
      ticketText +=
        "<left>Cantidad de sorteos:" + params.ticketItems.length + "<br>";
      //ticketText += "<right>" + params.ticketItems.length + "<br>";

      //Total Bet
      ticketText += "<left>Monto Total:" + params.total + "<br>";
      //ticketText += "<right>" + params.total + "<br>";

      //Messaje
      ticketText += "<center><line><br>";
      ticketText += '<center><big>"MUCHA SUERTE"<br>';
      ticketText += "<center><line><br>";

      //PRINT DATE
      ticketText += "<left>Fecha jugada:" + this.getPrintDate() + "<br>";
      //ticketText += "<right>" + this.getPrintDate() + "<br>";

      //BARDCODE
      var codeNumber = 123465892;
      ticketText += "<center><barcode128>" + codeNumber + "<br><cut>";

      return ticketText;
    },
    forceLength(target, len) {
      var out = String(target);
      while (out.length < len) {
        out += " ";
      }
      return out;
    },
    onDialogCancel() {
      this.printDialog = false;
    },
    getPrintDisabledStatus() {
      if (this.selectedNumbers.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    onUpdateBet(index) {      
     
      if(this.settingReventados){
        this.reventadoAmountBet = this.betButtons[index];
      }else{
        this.amountBet = this.betButtons[index];
      }      

      
    },
    onUpdateCustomBet() {

      this.customBet = true;

      if(this.settingReventados){

        this.currentPadTagert = "reventadoAmountBet";
        this.reventadoAmountBet = "-";  

      }else{
        
        this.currentPadTagert = "amountBet";
        this.amountBet = "-";  
        this.lblControlAceptar = 'Confirmar';
        this.alertType = 'info';
        this.alertText = "Ingrese un monto de apuesta y presione confirmar";
      }
     
    
    },
    onUpdateRaffleType(type) {    
    
       this.currentRaffleType = type;
       this.currentNumber = '';
       if(this.currentRaffleType.toLowerCase() != 'exacto'){
         this.reventadoAmountBet = '-';
         this.reventado = false;
       }
       this.setRaffleAlert();
     
    },
    onShowDates(){
      this.showAlertArrow = false;
      
    },
    onUpdateRaffleDay(index,on,time) {

   
     
     let selectedIndex = this.selectedDays.indexOf(this.days[index]);

      if(on){
        this.currentRaffleTime = time
        this.currentRaffleDay = this.days[index];

        let day = this.days[index];
        day.time = time;       
        if((this.selectedDays.length + this.selectedNumbers.length ) < this.MAX_NUMBERS && selectedIndex === -1){
          this.selectedDays.push(day);
        }else{
          this.alertText = 'No se pueden jugar mas de 10 números';
        }
      }else{
         
         //console.log('selectedIndex '+selectedIndex)
         this.selectedDays.splice(selectedIndex,1);
      }
   /* 
      for (let e = 0; e < this.selectedDays.length; e++) {
       console.log('Day '+this.selectedDays[e].weekday+' '+this.selectedDays[e].time)
        
      } */

     
      
     
    },
    onUpdateRaffleTime(index,time) {
      let selectedIndex = this.selectedDays.indexOf(this.days[index]);
      this.currentRaffleTime = this.times[index];
      if(selectedIndex > -1){
        this.selectedDays[selectedIndex].time = time;
      }
     /*  for (let e = 0; e < this.selectedDays.length; e++) {
       console.log('Day '+this.selectedDays[e].weekday+' '+this.selectedDays[e].time)
        
      } */
      
    },
    onUpdateRevetando(reventado) {
      
      this.reventado = reventado;

      if (!this.reventado) {

        this.reventadoAmountBet = '-';
        this.currentPadTagert = "currentNumber";       
        this.lblControlAceptar = 'Ingresar';
        this.settingReventados = false;
        this.setRaffleAlert();

      } else {

        this.settingReventados = true;
        this.reventadoAmountBet = this.amountBet;
        //this.currentPadTagert = "reventadoAmountBet";
        this.alertType = "info";
        this.alertText = "Confirme valor de apuesta para REVENTADOS";
        this.lblControlAceptar = 'Confirmar';
      }
    },
    onKeyboardUpdate(index) {
      //console.log('onKeyboardUpdate '+this.currentPadTagert)

     /*  if(this.currentState != this.RAFFLE_NUMBER_STATE && !this.customBet){
        this.clearCurrentState(this.RAFFLE_NUMBER_STATE);
        this.currentState = this.RAFFLE_NUMBER_STATE;       
      } */
      switch (this.currentPadTagert) {
        case "currentNumber":
          if (this.valueButtons[index] == "<") {
            if (this.duplicateNumber) {
              this.duplicateNumber = false;
            }
            this.currentNumber = String(this.currentNumber).slice(0, -1);
          } else if (this.valueButtons[index] == "BORRAR") {
            this.currentNumber = "";
          } else {
            if (this.duplicateNumber) {
              this.currentNumber = "";
              this.duplicateNumber = false;
            }
            if (this.currentNumber.length < 2) {
              this.currentNumber += this.valueButtons[index];
            }
          }
         
          break;
        case "amountBet":
          if (this.valueButtons[index] == "<") {
            if (String(this.amountBet).length > 1) {
              this.amountBet = String(this.amountBet).slice(0, -1);
            } else {
              this.amountBet = "-";
            }
          } else if (this.valueButtons[index] == "BORRAR") {
            this.amountBet = "-";
          } else {
             //console.log('onKeyboardUpdate '+typeof(this.amountBet))
            if (String(this.amountBet).length < 7) {
              this.amountBet = String(this.amountBet).replace("-", "");
              this.amountBet += this.valueButtons[index];
            }
          }
           //console.log('onKeyboardUpdate '+this.amountBet)
          //this.validateBet();

          break;
        case "reventadoAmountBet":
          if (this.valueButtons[index] == "<") {
            if (String(this.reventadoAmountBet).length > 1) {
              this.reventadoAmountBet = String(this.reventadoAmountBet).slice(
                0,
                -1
              );
            } else {
              this.reventadoAmountBet = "-";
            }
          } else if (this.valueButtons[index] == "BORRAR") {
            this.reventadoAmountBet = "-";
          } else {
            if (String(this.reventadoAmountBet).length < 7) {
              this.reventadoAmountBet = String(this.reventadoAmountBet).replace(
                "-",
                ""
              );
              this.reventadoAmountBet += this.valueButtons[index];
            }
          }

          //this.validateBet();

          break;

        default:
          break;
      }
    },
    onClear() {

      this.selectedNumbers = [];    
      this.currentNumber = '';
      this.selectedDays = [this.days[0]];
      this.amountBet = 100;
      this.reventado = false;
      this.currentPadTagert = 'currentNumber';
      if(!this.checkLimitTime()){
        this.currentRaffleTime = this.times[1]
      }else{
        this.currentRaffleTime = this.times[0]
      }
      this.clearDays = !this.clearDays;
      this.defaultTime = !this.defaultTime;
      this.defaultDay = !this.defaultDay;
      this.defaultBet = !this.defaultBet;
      this.reset = !this.reset;
      this.setRaffleAlert();
      

    },
    onDelete(index) {
      this.selectedNumbers.splice(index, 1);
      this.validateTotal(Number(this.getTotal));
      this.setRaffleAlert();
    },
    onAceptar() {


      if(this.settingReventados){

        if(this.validateReventadoBet()){
          this.settingReventados = false;
          this.lblControlAceptar = "Ingresar";
          if(this.customBet){
            this.customBet = false;
            this.currentPadTagert = 'currentNumber';
          }
          
          this.setRaffleAlert();
        }
       
      }else{

         if(this.customBet){
       
          if(this.validateBet()){
            this.customBet = false;
            this.lblControlAceptar = "Ingresar";
            this.currentPadTagert = 'currentNumber';
            this.setRaffleAlert();
          }
        }else{
          if(this.validateBet() && this.validateReventadoBet() && this.validateDays() && this.validateNumber()){
            this.insertRaffle();
          }
        }
      }

     
     
    },
    validateDays(){
      
      if(this.selectedDays.length > 0){
        return true;
      }else{
        this.alertType = 'info';
        this.alertText = "Debe seleccionar por lo menos un dia para el sorteo";
      }
    },
    insertRaffle(){
     

      for (let i = 0; i < this.selectedDays.length; i++) {
          
          
        
      
        var selectedNumber = {
          number: "",
          type: "",
          bet: "",
          reventadoBet: "",
          raffleDay: "",
          raffleTime: "",
          blindRooster: "",
        };

        if (this.blindRooster) {
          selectedNumber.blindRooster = "GT";
        }
        if (this.portrait) {
          selectedNumber.type = this.currentRaffleType
            .substring(0, 3)
            .toUpperCase();
        } else {
          selectedNumber.type = this.currentRaffleType;
        }

        selectedNumber.bet = this.amountBet;

        if (
          this.reventadoAmountBet != '-' &&
          String(this.currentRaffleType).toLowerCase() == "exacto"
        ) {
          selectedNumber.reventadoBet = this.reventadoAmountBet;
        } else {
          selectedNumber.reventadoBet = "";
        }

        selectedNumber.raffleDay =
          this.selectedDays[i].weekday + " " + this.selectedDays[i].date;
        selectedNumber.raffleTime = this.selectedDays[i].time;
        var time = "";
        if (String(this.selectedDays[i].time).toLowerCase() == "n") {
          time = "N";
        } else {
          time = "M";
        }
        if(!this.checkLimitTime() && this.selectedDays[i].weekday.toLowerCase() === 'hoy' ){
          time = 'N';
        }
        var day = this.selectedDays[i].date + " " + time;
        selectedNumber.date = day;
       
        selectedNumber.number = this.currentNumber;

        this.selectedNumbers.push(selectedNumber);

      
      }
      
     
      this.currentNumber = '';
      this.setRaffleAlert();
      this.blindRooster = false;
      //this.selectedDays = [this.days[0]];
      //this.amountBet = 100;
      //this.currentRaffleTime = this.times[0]
      
      

    },
    resetValues(resetDefault = false) {
      this.reventadoAmountBet = '-';
      this.currentNumber = "";
      this.reventado = false;
      this.blindRooster = false;
      this.reset = !this.reset;
      this.resetDefault = resetDefault;
      this.currentPadTagert = "currentNumber";
      this.acceptDisabled = true;

      if (this.resetDefault) {
        var now = new Date();
        var hours = now.getHours();

        if (hours > 12) {
          this.currentRaffleTime = this.times[1];
        } else {
          this.currentRaffleTime = this.times[0];
        }
        this.currentRaffleDay = this.days[0];
        this.amountBet = this.betButtons[0];
      }

      this.validateTotal(Number(this.getTotal), true);
    },
    onBlindRooster() {
     /*  if(this.currentState != this.RAFFLE_NUMBER_STATE){
        this.clearCurrentState(this.RAFFLE_NUMBER_STATE);
        this.currentState = this.RAFFLE_NUMBER_STATE;       
      } */
      switch (String(this.currentRaffleType).toLowerCase()) {
        case "primero":
        case "terminación":
          this.currentNumber = String(this.getRandomInRange(0, 9));
          break;
        case "exacto":
        case "reversible":
          this.currentNumber = String(this.getRandomInRange(0, 99));
        default:
          break;
      }
      this.blindRooster = true;
      if(!this.settingReventados){
        this.setRaffleAlert();
      }
      
      //this.validateNumber();
      //this.validateTotal(Number(this.getTotal), true);
     
    },
    setRaffleAlert() {
      switch (String(this.currentRaffleType).toLowerCase()) {
        case "primero":
          this.alertType = "info";
          this.alertText = "Ingrese un Nº entre 0 y 9, luego presione INGRESAR";
          
          break;
        case "terminación":
          this.alertType = "info";
          this.alertText = "Ingrese un Nº entre 0 y 9, luego presione INGRESAR";
          break;
        case "exacto":
          this.alertType = "info";
          this.alertText = "Ingrese un Nº de hasta 2 dígitos y presione INGRESAR";
          break;
        case "reversible":
          this.alertType = "info";
          this.alertText = "Ingrese un Nº de hasta 2 dígitos y presione INGRESAR";
          break;

        default:
          break;
      }

      if(this.currentNumber.length > 0){
         this.alertText = "Presione INGRESAR para ingresar la apuesta";
      }
    },
    validateNumber() {
      let valid = true;
     
      switch (String(this.currentRaffleType).toLowerCase()) {
        case "exacto":
          if (Number(this.currentNumber) > 99) {
            this.alertType = "error";
            this.alertText = "El Nº debe ser de un dígito entre 0 y 99";
            valid = false;
          }
          break;
        case "reversible":
          if (String(this.currentNumber.length > 1)) {
            var digits = String(this.currentNumber).split("");

            var equals_digit = [digits[0]];
            for (let e = 0; e < digits.length; e++) {
              if (equals_digit.indexOf(digits[e]) == -1) {
                equals_digit.push(digits[e]);
              }
            }
            if (digits.length > 1 && equals_digit.length == 1) {
              this.alertType = "error";
              this.alertText = "Los dígitos del Nº no pueden ser iguales";
              valid = false;
            }
          }
          if (Number(this.currentNumber) > 99) {
            this.alertType = "error";
            this.alertText = "El Nº debe ser de un dígito entre 0 y 99";
            valid = false;
          }

          break;
        case "primero":
          if (Number(this.currentNumber) > 9) {
            this.alertType = "error";
            this.alertText = "El Nº debe ser de un dígito entre 0 y 9";
            valid = false;
          }

          break;
        case "terminación":
          if (Number(this.currentNumber) > 9) {
            this.alertType = "error";
            this.alertText = "El Nº debe estar entre 0 y 9";
            valid = false;
          }

        default:
          break;
      }
      if (this.currentNumber == "") {       
        this.setRaffleAlert();
        valid = false;
      }else if(this.duplicateBet()){
        this.alertType = "error";
        this.alertText = "Ya se ha realizado esta apuesta";
        valid = false;
      
      }else if(((this.selectedNumbers.length  + this.selectedDays.length) > this.MAX_NUMBERS)){
        this.alertType = "error";
        this.alertText = "No se pueden jugar mas de 10 números";
        valid = false;
      }

      return valid;

    },
    validateBet() {

      let valid = true;

      if(this.amountBet == 0){
          this.alertType = "error";
          this.alertText = "El valor de apuesta no puede ser 0";
          valid = false;
      }else if (this.amountBet == "-") {
          this.alertType = "info";
          this.alertText = "Ingrese un valor para la apuesta";
          valid = false;
      }else if (Number(this.amountBet) < this.MIN_BET) {
          this.alertType = "error";
          this.alertText = "El valor de apuesta minimo es de " + this.MIN_BET;
          valid = false;
      }else if(Number(this.getTotal) + Number(this.amountBet) > this.MAX_BET){
          this.alertType = "error";
          this.alertText = "EL maximo de apuesta es de " + this.MAX_BET;
          valid = false;
      }
     
     
      return valid;
    },
    validateReventadoBet(){


      if(!this.reventado){
        return true;
      }

      let valid = true;     
      if(this.reventadoAmountBet === '-'){
        this.alertType = "info";
        this.alertText = "Ingrese un monto de apuesta para REVENTADOS";
        valid = false;
      }else  if(this.reventadoAmountBet === '0' || Number(this.reventadoAmountBet) === 0){
        this.alertType = "error";
        this.alertText = "El valor de apuesta para REVENTADOS no puede ser 0";
        valid = false;
      }else if(Number(this.reventadoAmountBet) >  Number(this.amountBet)){
        this.alertType = "error";
        this.alertText = "El monto de REVENTADOS no puede ser mayor al valor de apuesta";
        valid = false;
       
      }else if((Number(this.getTotal) + Number(this.amountBet) + Number(this.reventadoAmountBet)) > this.MAX_BET){
        this.alertType = "error";
        this.alertText = "EL maximo de apuesta es de " + this.MAX_BET;
        valid = false;
      }
       
      return valid;
    },
    setDates() {
      var weekDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      this.days = [
        {
          weekday: "HOY",
          date: dd + "/" + String(Number(mm)+1),
        },
      ];

      for (let i = 0; i < 8; i++) {
        //new Date(1995,11,17);
        var weekDay = new Date(yyyy, mm, Number(dd) + i + 1);
        var date =
          String(weekDay.getDate()).padStart(2, "0") +
          "/" +
          String(weekDay.getMonth() + 1).padStart(2, "0"); //January is 0!

        var day = {
          weekday: weekDays[weekDay.getDay()],
          date: date,
          time:'-'
        };

        this.days.push(day);
        
      }
     
    },
    getRandomInRange(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getPrintDate() {
      var weekDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      var months = [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ];
      var today = new Date();
      var dd = today.getDate();
      var mm = months[today.getMonth()+1];
      var yyyy = today.getFullYear();
      var weeDay = weekDays[today.getDay()];
      var hours = today.getHours();
      var minutes = this.getZeroPad(today.getMinutes());

      var printDate =
        weeDay +
        " " +
        dd +
        " " +
        mm +
        " " +
        yyyy +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        this.getHourTime(hours);

      return printDate;
    },
    getPrintTicketDate(){
     
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      var hours = today.getHours();
      var minutes = this.getZeroPad(today.getMinutes());

      var printDate =
        dd +
        "/" +
        mm +
        "/" +
        yyyy +
        "/" +
        hours +
        ":" +
        minutes;
        

      return printDate;
    },
    getHourTime(h) {
      return h >= 12 ? "PM" : "AM";
    },
    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? "" : "0") + n;
    },
    getIndex(index) {
      var alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return alphabet[index];
    },
  },
};
</script>