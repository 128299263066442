<template>
  <v-app class="lot-app">
    <div v-if="landscape" class="landscape">
      <v-icon class="cell-icon" >mdi-cellphone</v-icon>
      <v-icon class="cell-arrow-icon" >mdi-rotate-3d-variant</v-icon>
    </div>   
    <title>{{ title }}</title>
    <div v-if="showBar" dark dense class="app-bar">
      <div class="dash-element">
        <v-btn  @click="onBack" icon>
          <v-icon class="back-button-icon" color="#FFF" medium>mdi-view-dashboard</v-icon>
        </v-btn>
      </div>

      <!--  <v-toolbar-title><span class="user-name">{{ lblUserName }} {{ userName }}</span></v-toolbar-title>
      <v-btn class="ml-5" icon>
        <v-icon color="rgba(245, 245, 245, 0.900)" x-large>mdi-close-circle-outline</v-icon>
      </v-btn> -->
      <div class="date-element">
        <div class="bar-date">
          <v-icon class="date-icon" color="#FFF">mdi-calendar-blank</v-icon>
          <span class="ml-2">{{ lblDate }}</span>
        </div>
        <div class="bar-clock">
          <v-icon class="clock-icon" color="#FFF">mdi-clock</v-icon>
          <span class="ml-2">{{ lblClock }}</span>
        </div>
      </div>

      <div class="user-element">
        <span class="user-name">{{ userName }}</span>
        <v-btn icon>
          <v-icon class="user-icon" @click="logout" color="#FFF">mdi-emoticon-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <v-main class="lot-main">
      <router-view></router-view>
    </v-main>
    <!--  <v-footer v-if="showBar" padless dark class="lot-footer">
      <p class="footer-text">{{ lblFooter }}</p>
    </v-footer> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",

  data: () => ({
    lblDate: "05/03",
    lblClock: "17:00",
    userName: "",
    showBar: true,
    resolution: "",
    portrait:false,
    landscape:false,
    printing:false
  }),
  computed: {
    ...mapState({
      title: (state) => state.title,
    }),
  },
  updated() {
    if (this.$router.currentRoute.name == "login") {
      this.showBar = false;
    } else {
      this.showBar = true;
    }

    /*  if (this.$store.getters.user) {
      this.userName = "Juan Carlos Apellido";
    } */
  },
  created() {
   
    this.$store.dispatch("getUser").then(() => {
      this.userName = "Juan Carlos Apellido";
      //console.log('Seteando user');
      this.updateUserNameSize();
      
    });
    //console.log("Route " + this.$router.currentRoute.name);
    if (this.$router.currentRoute.name == "login") {
      this.showBar = false;
    }
    window.addEventListener("resize", this.resolutionUpdated);
  },
  destroyed() {
    window.removeEventListener("resize", this.resolutionUpdated);
  },
  mounted() {

   
   
    //console.log('App mounted '+this.$route.query.printer) 
    if(this.$route.query.printer && this.$route.query.printer === '1'){
        //console.log('Printer!!')
        this.$store.dispatch("setOnApk", true);
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', '/static/dist/cordova-assets/cordova.js')
        document.head.appendChild(recaptchaScript)
    }   
    this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    this.lblDate = dd + "/" + mm;
    this.resolution =  window.screen.availWidth + "x" + window.screen.availHeight;
    this.resolutionUpdated();

    //console.log("App user: " + this.$store.state.title);
    //console.log("Resolucion: " + this.resolution);
  },
  beforeDestroy() {
    window.clearTimeout(this.$options.timer);
  },
  methods: {
    updateUserNameSize(){
       this.userName = "Juan Carlos Apellido";
       //console.log("updateUserNameSize " + this.userName);
       if (this.portrait) {
        this.userName = String(this.userName).split(" ")[0];       
        //console.log("Pantalla chica " + this.userName);
       }
       
    },    
    resolutionUpdated() {
      var width = Number(window.screen.availWidth);
      var height = Number(window.screen.availHeight);
      //console.log("Resolution Updated " + width + "X" + height);
      if (width < height || width < 1230) {
        
        this.portrait = true;
         if(window.innerHeight < window.innerWidth){
          this.landscape = true;
      }else{
          this.landscape = false;
      }
        this.updateUserNameSize();      
          
      } else {
        this.portrait = false;
        this.updateUserNameSize();       
      }
     
      this.$store.dispatch("setMobile",this.portrait);
    },
    onBack() {
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    updateDateTime() {
      var now = new Date();
      var hours = now.getHours();
      var minutes = this.getZeroPad(now.getMinutes());
      this.lblClock = hours + ":" + minutes;
      this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
    },
    getHourTime(h) {
      return h >= 12 ? "PM" : "AM";
    },

    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? "" : "0") + n;
    },
  },
};
</script>
