<template>
  <div class="display-panel">
    <div class="display-back"></div>
    <div class="display-row">
      <div class="display-element">
        <div class="number-display">{{
          currentNumber
        }}</div>
      </div>
      <div class="display-element">
        <p class="number-bet-label">{{ lblDisplayBet | addCurrency(currency) }}</p>
        <p  class="number-bet">{{ amountBet }}</p>
      <!--   <v-fade-transition>
         <v-btn id="btnConfirmarBet"  v-show="showBetConfirm" @click="onConfirmarBetTap" class="confirmar-button">{{
          lblConfirmarBet
        }}</v-btn>
        </v-fade-transition> -->
      </div>
      <div class="display-element">
        <p class="number-bet-label">{{ lblReventadoBet }}</p>
        <div @click="onReventadoBetTap" class="d-flex align-center">
            <p  class="number-bet">{{ reventadoAmountBet }}</p>
       
        </div>
        <v-switch
          inset
          v-model="reventado"
          :label="lblReventados"
          :disabled="disabledReventado"
          @change="onReventadoTap"
          color=#0077AD
        ></v-switch>
       <!--  <v-btn id="btnReventado" :disabled="getDisabledStatus()" @click="onReventadoTap" class="reventado-button">{{
          lblReventados
        }}</v-btn> -->
      </div>
      <div class="display-element-notifications">
        <!-- <div :class="getAlertTitleClass()">
          
        </div> -->
        <p  class="notifications-title">{{ lblNotifications }}</p>
        <div class="notifications-alert" :class="getAlertTitleClass()">
          <v-icon v-if="alertType==='info'" class="alert-icon-info">mdi-exclamation-thick</v-icon>
          <v-icon v-if="alertType==='error'" class="alert-icon-error">mdi-alert</v-icon>
          <p class="alert-text">{{ alertText }}</p>
        </div>
      </div>
    </div>
    <div class="controls-row">
      <p class="controls-label">{{ lblControls }}</p>
      <div class="controls-container">
         <v-btn
          id="btnExacto"
          @click="onExactoClick"
          class="display-button"
          elevation="2"
           :disabled="disabledBetTypes"
        >
          {{ lblExacto }}
        </v-btn>
         <v-btn
          id="btnReversible"
          @click="onReversibleClick"
          class="display-button"
          elevation="2"
           :disabled="disabledBetTypes"
        >
          {{ lblReversible }}
        </v-btn>
        <v-btn
          id="btnPrimero"
          @click="onPrimeroClick"
          class="display-button"
          elevation="2"
           :disabled="disabledBetTypes"
        >
          {{ lblPrimero }}
        </v-btn>
        <v-btn
          id="btnTerminacion"
          @click="onTerminacionClick"
          class="display-button"
          elevation="2"
          :disabled="disabledBetTypes"
        >
          {{ lblTerminacion }}
        </v-btn>
       
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayPanel",
  data() {
    return {
      lblPrimero: "Primero",
      lblTerminacion: "Terminación",
      lblExacto: "Exacto",
      lblReversible: "Reversible",
      lblRafflesTitle: "Sorteos",
      lblRaffles: "Sorteo",
      lblDays: "Dias de la semana",
      lblReventados: "REVENTADOS",
      lblDisplayBet: "Valor de apuesta en ",
      lblReventadoBet: "Apuesta reventados",
      lblNotifications: "Notificaciones",
      lblControls: "Tipo de apuesta", 
      lblConfirmarBet: "Confirmar", 
      reventado:false,  
      currentRaffleType:'', 
      disabledReventado:false
    
    };
  },
  props: [
  "currentNumber", 
  "amountBet",
  "reventadoAmountBet",
  "alertType",
  "alertText",
  "currency",
  "reset",
  "showBetConfirm",
  "disabledConfirmarBet",
  "disabledBetTypes", 
  ],
  watch: {
    reset(val){
      this.resetValues();
    },   
   },
  created() {
    
  },
  mounted() {
    this.toggleActiveRaffle("btnExacto");
    this.updateRaffle("Exacto");
  },
  filters: {
    addCurrency(val,currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " "+currency;
      }
    },
  },
  methods: {   
    onCancelReventado(){
      this.$emit('on-cancel-reventado');
    },
    onBetTap(){
      this.$emit('on-bet'); 
    },
    onReventadoBetTap(){
      this.$emit('on-reventado-bet');      
          
    },
    onConfirmarBetTap(){
      this.$emit('on-confirmar-bet');
    },
    getDisabledStatus(){
      
      if(String(this.currentRaffleType).toLowerCase() == 'exacto' && this.currentNumber != '' && !this.disabledReventado){
        return false;
      }else{
        return true;
      }
    },
    resetValues(){
      this.reventado = false;      
    },
    
    getAlertTitleClass(){
      if(this.alertType == 'error'){
        return 'notifications-alert notification-alert-error';
      }else{
        return 'notifications-alert  notification-alert-info';
      }
    },
    updateRaffle(raffle) { 
      this.currentRaffleType = raffle;     
      this.$emit("update-raffle-type", raffle);
    },
    onPrimeroClick() {
      this.disabledReventado = true;
      this.reventado =  false;
      this.$emit('on-reventado-change',this.reventado);  
      this.toggleActiveRaffle("btnPrimero");
      this.updateRaffle("Primero");      
    },
    onTerminacionClick() {
      this.disabledReventado = true;
      this.reventado =  false;
      this.$emit('on-reventado-change',this.reventado);  
      this.toggleActiveRaffle("btnTerminacion");
      this.updateRaffle("Terminación");      
    },
    onExactoClick() {
      this.disabledReventado = false;
      this.toggleActiveRaffle("btnExacto");
      this.updateRaffle("Exacto");      
    },
    onReversibleClick() {
      this.disabledReventado = true;
      this.reventado =  false;
      this.$emit('on-reventado-change',this.reventado);  
      this.toggleActiveRaffle("btnReversible");
      this.updateRaffle("Reversible");
     
    },    
    onReventadoTap() {      
      this.$emit('on-reventado-change',this.reventado);           
    },
    setReventado(state){
      this.reventado = state;      
      //this.toggleActiveReventado(this.reventado);
      this.$emit("update-reventado",this.reventado);  
    },
    toggleActiveRaffle(id) {
      var buttons = document.getElementsByClassName("display-button");
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          document.getElementById(id).classList.add("display-button-active");
        } else {
          buttons[i].classList.remove("display-button-active");
        }
      }
    },
    toggleActiveReventado(active) {
      var button = document.getElementById("btnReventado");      
      if(active){
        button.classList.add("reventado-button-active");
      }else{
         button.classList.remove("reventado-button-active");
      }    
      
    },
   
  },
};
</script>
