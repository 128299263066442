<template>
  <div class="info-panel-portrait-tm">
   
    <div class="info-panel-display-portrait">
     
      <div
        v-for="(num, index) of numbers"
        :key="index"
        class="info-panel-number"
      >
        <div class="number-container">
          <div class="index">{{ getIndex(index) }}</div>
          <div class="number">{{ num.number }}</div>
        </div>

        <div v-if="num.blindRooster === ''" class="type">
          {{ num.type | capitalized }}
          <div class="date">{{ num.date }}</div>
        </div>
        <div v-else class="type">
          {{ num.type | capitalized }} ({{ num.blindRooster }})
          <div class="date">{{ num.date }}</div>
        </div>

        <div v-if="num.reventadoBet === ''" class="bet">
          {{ num.bet | addCurrency(currency, true) }}
        </div>
        <div v-else class="bet">
          {{ num.bet | addCurrency(currency, true) }}
          <p class="label-reventado">(R)</p>
          {{ num.reventadoBet | addCurrency(currency, true) }}
        </div>
        <div class="options">
          <v-btn class="option-button" icon @click="onDeleteClick(index)">
            <v-icon class="option-icon" color="black">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>  
   
   <v-navigation-drawer
      v-model="drawer"     
      right     
      absolute
      temporary
      
      width="50%"
      color="error"       
      
    >
    <div class="controls-container">
      <button
        @click="onClearClick"
        color="error"       
        class="info-panel-button"
      >      
        <v-icon class="control-button-icon">mdi-trash-can-outline</v-icon>
         {{ lblClear }}
      </button> 
    </div>
     
   </v-navigation-drawer>
   
   <div  @click="onShowControls" class="info-panel-controls">
     <v-icon class="info-panel-controls-icon">mdi-trash-can-outline</v-icon>
   </div>
   
  <!--   <div  @click="onClearClick" class="info-panel-controls">
       <v-btn
        @click="onClearClick"
        color="error"
        elevation="2"
        class="info-panel-button"
      >
        {{ lblClear }}
      </v-btn> 
    </div>  -->
  </div>
</template>

<script>
export default {
  name: "InfoPanel",
  data() {
    return {
      lblGameLogo: "Game Logo",
      lblClear: "Limpiar",
      lblRooster: "GALLO CIEGO",
      lblImprimir: "IMPRIMIR",
      lblSorteos: "Sorteos",
      lblEditar: "Editar",
      lblEliminar: "Eliminar",
      lblTotalsTitle: "Resumen",
      lblTotalBet: "",
      offset: true,
      currentGameLogoSrc: "",
      drawer:false
    };
  },
  props: ["numbers", "currency", "totalBet", "raffleDays", "raffleTimes"],
  created() {
    this.lblSorteos = "Sorteos: ";
    this.lblDays = "Dias: ";
    this.lblTotalBet = "Total apostado en";
    this.currentGameLogoSrc = "../assets/nuevosTiemposLogo.png";
  },
  filters: {
    capitalized(val) {
      
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    addCurrency(val, currency, before) {
      if (val != "0" && val != "") {
        if (before) {
          return currency + " " + val;
        } else {
          return val + " " + currency;
        }
      } else {
        return val;
      }
    },
  },
  methods: {
    getBetClass(bet, reventadoBet) {
      if (String(bet).length > 5 || String(reventadoBet).length > 5) {
        return "bet big-bet";
      } else {
        return "bet";
      }
    },
    onDeleteClick(index) {      
      this.$emit("delete-number", index);
    },
    getIndex(index) {
      var alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return alphabet[index];
    },
    onShowControls(){
      this.drawer = true;
    },
    onClearClick() {
      document.activeElement.blur();
      this.drawer = false;
      this.$emit("clear-numbers");
      
    },
  },
};
</script>