import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import print from 'print-js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    game: null,
    pdfUrl:'url pdf',
    isMobile:false,
    version:'lolo',
    title: 'Loteria',
    apkPrinterReady:false,
    onApk:false,
    engineResult: { success: false, error: {}, data: {} }
  },
  mutations: {
    setUser(state, user) {
      this.state.user = user;   
    },
    setApkPrinterReady(state, ready) {
      this.state.apkPrinterReady = ready;   
    },
    setOnApk(state, on) {
      this.state.onApk = on;   
    },
    setEngineResult(state, result) {
      this.state.engineResult = result;
    },
    setGame(state, game) {
      this.state.game = game;
    },
    setTitle(state, title) {
      this.state.title = title;
    },
    setVersion(state, version) {
      //console.log('Set version '+version)
      this.state.version = version;
    },
    setPdfUrl(state, url) {
      this.state.pdfUrl = url;
    },
    setMobile(state, mobile) {
      this.state.isMobile = mobile;
    },
  },
  actions: {
    getUser: async function ({ commit }) {

      var formData = new FormData();
      var axiosData = {
        method: 'post',
        url: 'getUser/',
        data: formData,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
      await axios(axiosData)
        .then(response => {

          var data = response.data;
          //console.log('getUser ' + data.user.authenticated);
          commit('setUser', data.user);

        })
        .catch(error => {
          console.log('Engine Error ' + error);
          commit('setUser', null);
        });
    },
    login: async function ({ commit }, params) {

      var formData = new FormData();
      formData.append('username', params.name);
      formData.append('password', params.password);

      var axiosData = {
        method: 'post',
        url: 'login/',
        data: formData,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
      await axios(axiosData)
        .then(response => {

          var data = response.data;
          var result = {}
          console.log('login ' + data);
          if (data.success) {
            result = { success: true };
            commit('setUser', data.user);
          } else {
            result = { success: false, error: data.error }
          }
          commit('setEngineResult', result);

        })
        .catch(error => {
          console.log('Engine Error ' + error);
          result = { success: false, error: error }
          commit('setEngineResult', result);
        });
    },
    logout: async function ({ commit }) {
      var axiosData = {
        method: 'post',
        url: 'logout/',
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
      await axios(axiosData)
        .then(response => {

          var data = response.data;
          if (data.success) {    
            //console.log('Logout '+data.user);       
            commit('setUser', data.user);
          } else {
            console.log('Engine Logout Error ' + data.error);
          }

        })
        .catch(error => {
          console.log('Engine Logout Error ' + error);
        });

    },
    setSelectedGame({ commit }, game) {
      commit('setGame', game);
    },
    setTitle({ commit }, title) {
      commit('setTitle', title);
    },
    setVersion({ commit }, version) {
      commit('setVersion', version);
    },
    setMobile({commit},mobile){
      commit('setMobile',mobile);
    },
    setOnApk({commit},on){
      commit('setOnApk',on);
    },
    setApkPrinterReady({commit},ready){
      commit('setApkPrinterReady',ready);
    },
   

    printTicket: async function ({ commit,state, getters }, params) {
      var formData = new FormData();
      var ticketItems = params.ticketItems;
      var raffleDays = params.raffleDays;
      var raffleTimes = params.raffleTimes;
      var raffles = params.raffles;
      var total = params.total;
      var game = params.game;


      formData.append("ticketItems", JSON.stringify(ticketItems));
      formData.append("raffleDays", JSON.stringify(raffleDays));
      formData.append("raffleTimes", JSON.stringify(raffleTimes));
      formData.append("raffles", JSON.stringify(raffles));
      formData.append("total", total);
      formData.append("game", game);
      var axiosData = {
        method: "post",
        url: "getTicket/",
        data: formData,
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFToken",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
      await axios(axiosData)
        .then(response => {

          var fileName = 'Ticket.pdf';
          //const url = window.URL.createObjectURL(new Blob([response.data],{type: "application/pdf"}));
          var byteArray = new Uint8Array(response.data.length/2);
          for (var x = 0; x < byteArray.length; x++){
              byteArray[x] = parseInt(response.data.substr(x*2,2), 16);
          }
          var blob = new Blob([response.data],{type: "application/pdf"});
          const url = window.URL.createObjectURL(blob);
          
          //commit('setPdfUrl',url);
          //console.log('Store '+state.isMobile)
          if(state.isMobile){
            window.open(url);
          }else{
            print({ printable: url, type: 'pdf', honorMarginPadding: false });
          }
          


        })
        .catch(error => {
          console.log('Get ticket Error ' + error);

        });

    }

  },
  getters: {
    user: state => {
      return state.user;
    },
    version: state => {
      return state.version;
    },
    engineResult: state => {
      return state.engineResult;
    }
  }
})
